import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getHotelsApi } from "../../api/hotel/index";
import { getHotelApi } from "../../api/hotel/hotel-details";
import { deleteHotelApi } from "../../api/hotel/delete-hotel";

//fetch hotels API.
export const fetchHotels = createAsyncThunk(
  "hotels/fetchHotels",
  async () => {
    const response = await getHotelsApi();
    return response.data;
  }
);

//fetch hotel API.
export const fetchHotel = createAsyncThunk(
  "hotels/fetchHotel",
  async (id) => {
    const response = await getHotelApi(id);
    return response.data;
  }
);

//delete hotel API.
export const deleteHotel = createAsyncThunk(
  "hotels/deleteHotel",
  async (id) => {
    const response = await deleteHotelApi(id);
    return response.data;
  }
);

const initialState = {
  hotels: [],
  rooms: [],
  hotel: {},
  name: "",
  city: "",
  status: 'idle' | 'loading' | 'succeeded' | 'failed',
  error: null,

};

const hotelSlice = createSlice({
  name: "hotels",
  initialState,
  reducers: {
    
    //new room added.
    addRoom: (state, action) => {
      state.rooms.push(action.payload);
    },

    //remove room.
    removeSelectedRoom: (state, action) => {
      state.rooms = null;
    },

    //update city.
    updateCity: (state, action) => {
      state.hotel.city = action.payload;
    },

    //update hotel name.
    updateHotelName: (state, action) => {
      state.hotel.name = action.payload;
    },

    updateContractStart: (state, action) => {
      state.hotel.contract_start = action.payload;
    },

    updateContractExpiry: (state, action) => {
      state.hotel.contract_expiry = action.payload;
    },

    //rooms updated.
    updateRoom: (state, action) => {
      const { index, data } = action.payload;
      return {
        ...state,
        rooms: state.rooms.map((room, idx) =>
          idx === index ? { ...room, ...data } : room
        ),
      };
    },

    //set rooms
    setRooms: (state, action) => {
      
      state.rooms = action.payload;

    },

    updateRoomQuantity: (state, action) => { 
      state.rooms = state.rooms.map((room) => {
          if (room.id === action.payload.id) {
              room = {
                ...room,
                no_of_rooms: action.payload.no_of_rooms
              }
          }
          return room;
    });
  },

  },

  extraReducers(builder) {
    builder.addCase(fetchHotels.fulfilled, (state, action) => {
      state.hotels=action.payload.data;
    })
    
    .addCase(fetchHotel.fulfilled, (state, action) => {
      state.hotel=action.payload;
      state.rooms=action.payload.rooms;
    })

    .addCase(deleteHotel.fulfilled, (state, action) => {
      state.hotels=state.hotels.filter((hotel) => hotel.id !== action.payload.id);
    })

  }
});

export const selectHotels = (state) => state.hotels.hotels;
export const selectHotel = (state) => state.hotels.hotel;
export const { removeSelectedRoom, addRoom, updateRoom, setRooms, updateCity, updateHotelName, updateContractStart, updateContractExpiry, updateRoomQuantity } = hotelSlice.actions;
export const selectRooms = (state) => state.hotels.rooms;
export const selectLoading = (state) => state.hotels.status === 'loading';
export default hotelSlice.reducer;