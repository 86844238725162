const user_key = 'user'
const token_key = 'access_token'
//const refresh_key = 'refresh_token'

export const addKeyToStorage = (key, object) => {
    localStorage.setItem(key, JSON.stringify(object))
}

export const removeKeyFromStorage = (key) => {
    localStorage.removeItem(key)
}

export const getKeyFromStorage = (key) => {
    const obj = localStorage.getItem(key)
    return JSON.parse(obj);
}

export const setUser = (user) => {
    addKeyToStorage(user_key, user)
}

export const getUser = () => {
    return getKeyFromStorage(user_key)
}

export const setAccessToken = (token) => {
    addKeyToStorage(token_key, token)
}

export const removeAccessToken = () => {
    removeKeyFromStorage(token_key);
}

export const getAccessToken = () => {
    return getKeyFromStorage(token_key)
}

export const setPackage =  (package_id) => {
    sessionStorage.setItem('package', package_id)
}

export const getPackage =  () => {
    return sessionStorage.getItem('package')
}