import React, { useEffect } from "react";
import CSS from "./Quotation.module.css";
import deleteIcon from '../../../assets/icons/delete.svg';
import editIcon from '../../../assets/icons/edit.svg';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectQuotationItems, selectQuotations } from "../../../services/features/quotationSlice/quotationSlice";
import { QuotationStatus } from "../../../services/client/utils";
import { fetchQuotationsAction, updateQuotationAction } from "../../../services/client/quotations";
import Select from "react-select";
import { deleteQuotationAction } from "../../../services/client/quotations";

const Quotation = () => {
  const quotationsData = useSelector(selectQuotations);

  //calling the action for fetching the quotations.
  useEffect(() => {
    fetchQuotationsAction('status[]=new_inquiry&status[]=quotation_sent');
  }, []);

  const setDateeFormat = (date) => {
    //convert the data into only mm/dd/yyyy format.
    const newDate = new Date(date);
    const formattedDate = newDate.toLocaleDateString();
    return formattedDate;
  };

    //custom styles for the select component
    const customStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: 30,
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: 0,
      }),
      option: (provided, state) => ({
        ...provided,
        borderRadius: state.isSelected ? 0 : 0,
      }),
    };

    const getQuotationStatus = (statusArray, statusValue) => {
      return statusArray.find(status => status.value === statusValue);
    };

    const handleQuotationStatusChange = (selectedOption, quotation) => {
      updateQuotationAction(quotation.id, { status: selectedOption.value});   
      
      //on changing the status if the selectedoption.value === 'final_payment_received' the pass the query parameter to the operations
     
      
    }

  return (
    <div className={CSS.mainQuotationContainer}>
      <div className={CSS.topContainer}>
        {/*<div className={CSS.searchAndCalenderContainer}>*/}
        {/*  <div className={CSS.singleItemContainer}>*/}
        {/*    <div className={CSS.headingClass}> Search Text </div>*/}
        {/*    <input type="text" placeholder="Search by quotation number" className={CSS.searchInput} />*/}
        {/*  </div>*/}

        {/*  <div className={CSS.singleItemContainer}>*/}
        {/*    <div className={CSS.headingClass}> Start Date </div>*/}
        {/*    <input type="date" className={CSS.dateInput} />*/}
        {/*  </div>*/}

        {/*  <div className={CSS.singleItemContainer}>*/}
        {/*    <div className={CSS.headingClass}> End Date </div>*/}
        {/*    <input type="date" className={CSS.dateInput} />*/}
        {/*  </div>*/}

        {/*</div>*/}
        <div className={CSS.addQuotationButtonContainer}>
          <Link to={"/quotation/newquotation"}> <button className={CSS.addQuotationButton}> New Quote </button></Link>
          {/*<button className={CSS.addQuotationButton}> Select Status </button>*/}
        </div>
      </div>

      <div className={CSS.tableContainer}>
        <table className={`${CSS["TableContainer"]} ${CSS["TableCustom"]}`}>
          <thead>
            <tr className={CSS.TableHead}>
              <th className={CSS.SerialNumber}>ID</th>
              <th className={CSS.TypeInput}>Quotation Date</th>
              <th className={CSS.TypeInput}>Agent Name</th>
              <th className={CSS.TypeInput}>No# of Pax</th>
              <th className={CSS.TypeInput}>Guest Name</th>
              <th className={CSS.TypeInput}>Status</th>
              <th className={CSS.TypeInput}>Payment Date</th>
              <th className={CSS.TypeInput}>Payment Method</th>
              <th className={CSS.TypeInput}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {quotationsData.map((quotation, index) => (
              <tr key={quotation.id || index} className={index % 2 === 0 ? CSS.backGroundColor : CSS.backGroundColor1}>
                <td className={CSS.SerialNumber}>
                  <Link to={`/quotation/details/${quotation.id}`} className={CSS.linkClass}>
                    {quotation.id}
                  </Link>
                </td>
                <td className={CSS.TypeInput}>{setDateeFormat(quotation.created_at)}</td>
                <td className={CSS.TypeInput}>{quotation.request?.agent?.company || ''}</td>
                <td className={CSS.TypeInput}>{quotation.request?.no_of_pax || '0'}</td>
                <td className={CSS.TypeInput}>{quotation.request?.customer_name || ''}</td>
                <td className={CSS.TypeInput}>
                    <Select
                        className={CSS.SelectContainer}
                        options={QuotationStatus}
                        value={getQuotationStatus(QuotationStatus, quotation.status)}  
                        onChange={(selectedOption) => {
                          handleQuotationStatusChange(selectedOption, quotation)
                        }}
                        placeholder="Request ID"
                        styles={customStyles}
                    />
                </td>
                <td className={CSS.TypeInput}>{"12-05-2024 (dummy)"}</td>
                <td className={CSS.TypeInput}>{"Bank (dummy)"}</td>
                <td className={CSS.actionsColumn}>
                  <div className={CSS.actionsContainer}>
                    <img src={deleteIcon} alt="delete" className={CSS.iconContainer} onClick={() =>deleteQuotationAction(quotation.id)}></img>
                    <Link to={`/quotation/details/${quotation.id}`}><img src={editIcon} alt="edit" className={CSS.iconContainer}></img></Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>   
    </div>
  );
};

export default Quotation;
