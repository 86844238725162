import { configureStore } from "@reduxjs/toolkit";
import hotelsReducer from "./hotels/hotelSlice";
import sightseeingsReducer from "./sightseeings/SightSeeingsSlice";
import transferReducer from "./transfers/transferSlice";
import airportReducer from "./airports/airportSlice";
import agentsReducer from "./agent/agentSlice"
import bookingrequestReducer from "./bookingrequest/bookingrequestSlice";
import quotationReducer from "./quotationSlice/quotationSlice";

export const store = configureStore({
  reducer: {
    hotels: hotelsReducer,
    sightseeings: sightseeingsReducer,
    transfers: transferReducer,
    airports: airportReducer,
    agents:agentsReducer,
    bookingrequests:bookingrequestReducer,
    quotations:quotationReducer
  },
});
