import React, { useState, useRef, useEffect } from 'react';
import CSS from './SightSeeingDetails.module.css';
import HotelImageIcon from '../../../../assets/icons/hotel-dummy.svg';
import { Cities } from '../../../../services/client/utils';
import { setSightSeeingAction } from '../../../../services/client/sightseeings';
import { selectSightseeing } from '../../../../services/features/sightseeings/SightSeeingsSlice';
import { useSelector } from 'react-redux';
import { getSightSeeingByIDAction } from '../../../../services/client/sightseeings/index';
import { useNavigate, useParams } from 'react-router-dom';
import { updateSightSeeingAPI } from '../../../../services/client/sightseeings/index';

const SightSeeingDetails = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const fileInputRef = useRef(null);
  const [attachments, setAttachments] = useState([]);
  const sightSeeing = useSelector(selectSightseeing);
  const sightID = useParams();


  //setSightSeeingAction({ code: '', city: '', name: '', inclusions: '', ticket_only: 0, price_sic: 0, by_car: 0, by_suv: 0, by_van: 0, by_boat: 0, by_ferry: 0, images: [] });
  useEffect(() => {
    const fetchSightSeeingDetails = async () => {
      getSightSeeingByIDAction(sightID.id);
    };

    fetchSightSeeingDetails();
  }, []);


  const selectedCity = sightSeeing && sightSeeing.city;
  const sightseeingName = sightSeeing && sightSeeing.name;
  const SightSeeingCode = sightSeeing && sightSeeing.code;
  const sightSeeingsInclusions = sightSeeing && sightSeeing.inclusions;


  const ticketsOnly = useSelector(selectSightseeing).ticket_only;
  const priceSIC = useSelector(selectSightseeing).price_sic;
  const byCar = useSelector(selectSightseeing).by_car;
  const bySUV = useSelector(selectSightseeing).by_suv;
  const byVan = useSelector(selectSightseeing).by_van;
  const byFerry = useSelector(selectSightseeing).by_ferry;
  const byBoat = useSelector(selectSightseeing).by_boat;


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  //handling the sightseeing name change.
  const handleSightSeeingNameChange = (e) => {
    setSightSeeingAction({ name: e.target.value });
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const selectCity = (city) => {
    setSightSeeingAction({ city: city });
    toggleDropdown();
  };

  const handleCodeChange = (e) => {
    setSightSeeingAction({ code: e.target.value });
  };

  // Function to handle image upload
  const handleImageUpload = (e) => {

    const file = e.target.files[0];
    if (file) {
      setAttachments(file);
    } else {
      setAttachments(null);
    }

  };

  const handleFileUpload = (e) => {
    fileInputRef.current.click();
  };

  // Function to handle the ticket only price
  const handleTicketsOnly = (e) => {
    setSightSeeingAction({ ticket_only: e.target.value });
  };

  // Function to handle the price for SIC
  const handlePriceSIC = (e) => {
    setSightSeeingAction({ price_sic: e.target.value });
  };

  // Function to handle the price for car
  const handlePriceByCar = (e) => {
    setSightSeeingAction({ by_car: e.target.value });
  };

  // Function to handle the price for SUV
  const handlePriceBySUV = (e) => {
    setSightSeeingAction({ by_suv: e.target.value });
  };

  // Function to handle the price for Van
  const handlePriceByVan = (e) => {
    setSightSeeingAction({ by_van: e.target.value });
  };

  // Function to handle the price for Ferry
  const handlePriceByFerry = (e) => {
    setSightSeeingAction({ by_ferry: e.target.value });
  };

  // Function to handle the price for Boat
  const handlePriceByBoat = (e) => {
    setSightSeeingAction({ by_boat: e.target.value });
  };

  // Function to handle the inclusions
  const handleInclusionsUpdate = (e) => {
    setSightSeeingAction({ inclusions: e.target.value });
  };

  const navigate = useNavigate();

  //update sightseeing.
  const updateSightSeeing = () => {
    const data = {
      id: sightID.id,
      city: selectedCity,
      name: sightseeingName,
      code: SightSeeingCode,
      inclusions: sightSeeing.inclusions,
      types: [
        { type: 0, cost: ticketsOnly },
        { type: 1, cost: priceSIC },
        { type: 2, cost: byCar },
        { type: 3, cost: bySUV },
        { type: 4, cost: byVan },
        { type: 5, cost: byBoat },
        { type: 6, cost: byFerry }
      ],

      images: sightSeeing.images
    };

   // console.log(data);
    //here call the api for updating the sightseeing.
    updateSightSeeingAPI(attachments, data, navigate);

  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  const BackGroundImage = sightSeeing && sightSeeing.images[0] && sightSeeing.images[0].file;

  return (
    <div className={CSS.containerPage}>
      <div className={CSS.containerBackground} style={{ backgroundImage: `url(${BackGroundImage})` }}></div>
      <div className={CSS.innerContainer}>{sightseeingName}</div>
      <div className={CSS.hotelAndCityContainer}>
        <div className={CSS.cityContainer}>
          <div className={CSS.cityTitle}>City</div>
          <div ref={dropdownRef}>
            <input
              type="text"
              className={CSS.searchBarCity}
              onClick={toggleDropdown}
              value={selectedCity || 'Select City'}
            />
            {dropdownVisible && (
              <div className={CSS.dropdownContent}>
                {Cities.map((city, index) => (
                  <div key={index} onClick={() => selectCity(city)} className={CSS.dropdownItem}>
                    {city}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={CSS.hotelContainer}>
          <div className={CSS.cityTitle}>Sightseeing's Name</div>
          <input type="text" value={sightseeingName} className={CSS.searchBarCity} onChange={handleSightSeeingNameChange} />
        </div>
      </div>

      <div className={CSS.containerForAligningSightseeing}>
        <div className={CSS.cityAndSearchbarSightseeings}>
          <div className={CSS.cityTitle}>Code</div>
          <input type="text" className={CSS.sightseeingCitySearch} value={SightSeeingCode} onChange={handleCodeChange} />
        </div>

        <div className={CSS.sightseeingImageContainer}>
          <div className={CSS.titlesSightseeings}>Sightseeing's Image</div>
          <div className={CSS.sightseeingsInputImage} onClick={handleFileUpload}>
            <label htmlFor="hotelImageInput">
              <img src={HotelImageIcon} alt="Hotel Placeholder" className={CSS.sightseeingInputImageIcon} />
            </label>
            <input type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleImageUpload}
              style={{ display: 'none' }} />
          </div>
        </div>
      </div>

      <div className={CSS.containerForAligningSightseeing}>
        <div className={CSS.innerContainerForAligningSightseeing}>
          <div className={CSS.inputsAndButtons}></div>
          <div className={CSS.buttonsContainer}>
            <button className={CSS.sightseeingButtons}>Tickets Only</button>
            <button className={CSS.sightseeingButtons}>Price/SIC</button>
            <button className={CSS.sightseeingButtons}>By Car</button>
            <button className={CSS.sightseeingButtons}>By SUV</button>
          </div>

          <div className={CSS.inputsContainer}>
            <input type="text" className={CSS.sightseeingInput} value={ticketsOnly} onChange={handleTicketsOnly} />
            <input type="text" className={CSS.sightseeingInput} value={priceSIC} onChange={handlePriceSIC} />
            <input type="text" className={CSS.sightseeingInput} value={byCar} onChange={handlePriceByCar} />
            <input type="text" className={CSS.sightseeingInput} value={bySUV} onChange={handlePriceBySUV} />
          </div>

          <div className={CSS.buttonsContainer}>
            <button className={CSS.sightseeingButtonsOtherColumn}>By Van</button>
            <button className={CSS.sightseeingButtonsOtherColumn}>By Ferry</button>
            <button className={CSS.sightseeingButtonsOtherColumn}>By Speedboat</button>
          </div>

          <div className={CSS.inputsContainer}>
            <input type="text" className={CSS.sightseeingInputOtherColumn} value={byVan} onChange={handlePriceByVan} />
            <input type="text" className={CSS.sightseeingInputOtherColumn} value={byFerry} onChange={handlePriceByFerry} />
            <input type="text" className={CSS.sightseeingInputOtherColumn} value={byBoat} onChange={handlePriceByBoat} />
          </div>

          <div className={CSS.inspectorContainer}>
            <div className={CSS.titlesSightseeingsInclusions}>Inclusions</div>
            <textarea className={CSS.sightseeingDescription} value={sightSeeingsInclusions} onChange={handleInclusionsUpdate} />
          </div>
        </div>
      </div>

      {/* Save Button */}
      <div className={CSS.sightseeingsSaveButton}>
        <button className={CSS.sightseeingsSaveButtonText} onClick={updateSightSeeing}>Save</button>
      </div>
    </div>
  );
};

export default SightSeeingDetails;
