import React, { useEffect, useState } from 'react';
import CSS from './SightModal.module.css';
import { useSelector } from 'react-redux';
import { selectSightseeing } from '../../../services/features/sightseeings/SightSeeingsSlice';
import { getSightSeeingByIDAction } from '../../../services/client/sightseeings';
import { setCostPerPaxAction, setPriceAction, setResourseValueIdAction } from '../../../services/client/quotations';
import { selectBookingRequest } from '../../../services/features/bookingrequest/bookingrequestSlice';


const SightModal = ({ onClose, sightID, index }) => {
  const [checkedIndex, setCheckedIndex] = useState(null);
  const [selectedTransport, setSelectedTransport] = useState(null);

  const SightSeeing = useSelector(selectSightseeing);
  const bookingRequestData = useSelector(selectBookingRequest);

  // Bring in the sightseeing data by id
  useEffect(() => {
    getSightSeeingByIDAction(sightID);
  }, [sightID]);

  // Ensure SightSeeing has the necessary properties
  const labels = SightSeeing ? [
    { type: 0, cost: SightSeeing.ticket_only || 0 },
    { type: 1, cost: SightSeeing.price_sic || 0 },
    { type: 2, cost: SightSeeing.by_car || 0 },
    { type: 3, cost: SightSeeing.by_suv || 0 },
    { type: 4, cost: SightSeeing.by_van || 0 },
    { type: 5, cost: SightSeeing.by_boat || 0 },
    { type: 6, cost: SightSeeing.by_ferry || 0 }
  ] : [];

  const transportLabels = [
    'Tickets only',
    'Price/SIC',
    'By Car',
    'By SUV',
    'By Van',
    'By Speedboat',
    'By Ferry'
  ];

  const handleCheckboxChange = (idx) => {
    if (checkedIndex === idx) {
      setCheckedIndex(null);
      setSelectedTransport(null);
    } else {
      setCheckedIndex(idx);

      //setting the resource value id.
      setResourseValueIdAction({index: index, value: labels[idx].type});
      
      setSelectedTransport({
        transportType: transportLabels[idx],
        price: labels[idx].cost
      });

    }
  };

  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  const handleAddDetails = () => {
    if (selectedTransport) {

      //setting the price of the selected transport.
      setPriceAction({index: index, Price: selectedTransport.price});
      setCostPerPaxAction({index: index, cost: selectedTransport.price / bookingRequestData.no_of_pax});

      onClose();
    }
  };

  return (
    <div className={CSS.overlay}>
      <div className={CSS.popOut}>
        <h2 className={CSS.headingClass}>Sight Seeing Details</h2>

        <table className={CSS.table}>
          <thead>
            <tr className={CSS.theadRow}>
              <th className={CSS.th}>Sr.No</th>
              <th className={`${CSS.th} ${CSS.labelColumn}`}>Transport</th>
              <th className={CSS.th}>Price</th>
              <th className={CSS.th}>Choose Transport</th>
            </tr>
          </thead>
          <tbody>
            {labels.map((label, index) => (
              <tr
                key={index}
                className={`${CSS.tbodyRowHover} ${index % 2 === 0 ? CSS.tbodyRowEven : CSS.tbodyRowOdd}`}
              >
                <td className={CSS.td}>{index + 1}</td>
                <td className={`${CSS.td} ${CSS.labelColumn}`}>{transportLabels[index]}</td>
                <td className={CSS.td}>{label.cost}</td>
                <td className={CSS.td}>
                  <input
                    type="checkbox"
                    className={CSS.checkbox}
                    checked={checkedIndex === index}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className={CSS.buttonContainer}>
          <button className={CSS.modalButtons} onClick={onClose}>Close</button>
          <button className={CSS.modalButtons} onClick={handleAddDetails}>Add Details</button>
        </div>
      </div>
    </div>
  );
};

export default SightModal;
