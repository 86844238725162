import React, { useState, useRef, useEffect } from 'react';
import HotelImageIcon from '../../../../assets/icons/hotel-dummy.svg';
import './AddSightSeeing.css';
import { Cities } from '../../../../services/client/utils';
import { setSightSeeingAction } from '../../../../services/client/sightseeings';
import { createSightseeing } from '../../../../services/client/sightseeings/index';
import { selectSightseeing } from '../../../../services/features/sightseeings/SightSeeingsSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AddSightSeeing = () => {
  const [selectedCity, setSelectedCity] = useState('');
  const [showCityOptions, setShowCityOptions] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image file
  const fileInputRef = useRef(null);
  const [attachments, setAttachments] = useState(null);
  const navigate = useNavigate();
  const sightSeeings = useSelector(selectSightseeing);

  //actions to set the values in the redux store.
  const handleCitySelect = (city) => {
    setSightSeeingAction({ city: city });
    setSelectedCity(city);
    setShowCityOptions(false);
  };

  //actions to set the values in the redux store.
  const handleSightSeeingName = (e) => {
    setSightSeeingAction({ name: e.target.value });
  };

  //actions to set the values in the redux store.
  const handleCodeChange = (e) => {
    setSightSeeingAction({ code: e.target.value });
  };

  //actions to set the values in the redux store.
  const handleInclusions = (e) => {
    setSightSeeingAction({ inclusions: e.target.value });
  };

  //creating a new sightseeing.
  const createSightSeeing = () => {
    const data = {
      city: sightSeeings.city,
      name: sightSeeings.name,
      code: sightSeeings.code,
      inclusions: sightSeeings.inclusions,
      types: [
        {
          type:0,
          cost: sightSeeings.ticket_only
        },
        {
          type:1,
          cost: sightSeeings.price_sic
        },
        {
          type:2,
          cost: sightSeeings.by_car
        },
        {
          type:3,
          cost: sightSeeings.by_suv
        },
        {
          type:4,
          cost: sightSeeings.by_van
        },
        {
          type:5,
          cost: sightSeeings.by_boat
        },
        {
          type:6,
          cost: sightSeeings.by_ferry
        }
      ]
      
    };
    

    //calling the sight seeing api.
    createSightseeing(attachments, data, navigate);
  };
  // Function to handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0]; // Get the selected file
    setAttachments(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(file);
    }
  };

  //click handler for the image input.
  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  //click handler for the city input.
  const handleSearchBarClick = () => {
    setShowCityOptions(!showCityOptions);
  };

  const handleDocumentClick = (e) => {
    const dropdown = document.querySelector('.dropdown');
    if (dropdown && !dropdown.contains(e.target)) {
      setShowCityOptions(false);
    }
  };

  //useEffect to add event listener for the document click.
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const handleTicketsOnly = (e) => {
    setSightSeeingAction({ ticket_only: parseInt(e.target.value) });
  }

  const handlePrices = (e) => {
    setSightSeeingAction({ price_sic: parseInt(e.target.value) });
  }

  const handleCarPrice = (e) => {
    setSightSeeingAction({ by_car: parseInt(e.target.value) });
  }

  const handleSUVPrice = (e) => {
    setSightSeeingAction({ by_suv: parseInt(e.target.value) });
  }

  const handleVanPrice = (e) => {
    setSightSeeingAction({ by_van: parseInt(e.target.value) });
  }

  const handleFerryPrice = (e) => {
    setSightSeeingAction({ by_ferry: parseInt(e.target.value) });
  }

  const handleBoatPrice = (e) => {
    setSightSeeingAction({ by_boat: parseInt(e.target.value) });
  }

  return (
    <div className='container-sightSeeing'>
      <div className='container-for-aligning-sightseeing'>
        <div className='city-and-searchbar-sightseeings'>
          <div className='titles-sightseeings'>City</div>
          <div className="dropdown" onClick={handleSearchBarClick}>
            <input
              type="text"
              className="citySearch"
              placeholder="Select city"
              value={selectedCity}
              readOnly
            />
            {showCityOptions && (
              <div className="dropdown-content">
                {Cities.map((city, index) => (
                  <div key={index} onClick={() => handleCitySelect(city)}>{city}</div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className='sightseeing-searchbar'>
          <div className='titles-sightseeings'>Sight Seeing's Name</div>
          <input type="text" className="sightseeing-name-search" placeholder="Sight Seeing's Name" onChange={handleSightSeeingName} />
        </div>
      </div>
      <div className='container-for-aligning-sightseeing'>
        <div className='city-and-searchbar-sightseeings'>
          <div className='titles-sightseeings'>Code</div>
          <input
            type="text"
            className="sightseeing-citySearch"
            placeholder="Code"
            onChange={handleCodeChange}
          />
        </div>
        <div className='sightseeing-image-container'>
          <div className='titles-sightseeings-Image'>Sightseeing's Image</div>
          <div className='sightseeings-input-image' onClick={handleImageClick}>
            <label htmlFor="hotelImageInput">
              <img src={selectedImage ? URL.createObjectURL(selectedImage) : HotelImageIcon} alt='Hotel Placeholder' className='sightseeing-input-image-icon' />
            </label>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleImageUpload}
            />
          </div>
        </div>
      </div>
      <div className='container-for-aligning-sightseeing'>
        <div className='inner-container-for-aligning-sightseeing'>
          <div className='inputs-and-buttons'></div>
          <div className='buttons-container'>
            <button className='sightseeing-buttons'>Tickets Only</button>
            <button className='sightseeing-buttons'>Price/SIC</button>
            <button className='sightseeing-buttons'>By Car</button>
            <button className='sightseeing-buttons'>By SUV</button>
            <button className='sightseeing-buttons'>By Van</button>
          </div>
          <div className='inputs-container'>
            <input type="text" className="sightseeing-input" placeholder="Price" onChange={handleTicketsOnly}/>
            <input type="text" className="sightseeing-input" placeholder="Price" onChange={handlePrices}/>
            <input type="text" className="sightseeing-input" placeholder="Price" onChange={handleCarPrice}/>
            <input type="text" className="sightseeing-input" placeholder="Price" onChange={handleSUVPrice}/>
            <input type="text" className="sightseeing-input" placeholder="Price" onChange={handleVanPrice}/>
          </div>
          <div className='buttons-container'>
            <button className='sightseeing-buttons-other-column'>By Ferry</button>
            <button className='sightseeing-buttons-other-column'>By Speedboat</button>
          </div>
          <div className='inputs-container'>
            <input type="text" className="sightseeing-input-other-column" placeholder="Price" onChange={handleFerryPrice}/>
            <input type="text" className="sightseeing-input-other-column" placeholder="Price" onChange={handleBoatPrice}/>
          </div>
          <div className='inspector-container'>
            <div className='titles-sightseeings'>Inclusions</div>
            <textarea className='sightseeing-description' placeholder='Description' onChange={handleInclusions} />
          </div>
        </div>
      </div>
      <div className='sightseeings-save-button'>
        <button className='sightseeings-save-button-text' onClick={createSightSeeing}>Add</button>
      </div>
    </div>
  );
};

export default AddSightSeeing;
