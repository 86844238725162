import React, { useEffect } from 'react'
import './SightSeeings.css'
import SightSeeingsCard from '../../shared/Card/Card'
import AddButton from '../../shared/AddButton/AddButton'
import { useSelector } from 'react-redux'
import { selectSightseeings, selectSightseeingsLoading } from '../../../services/features/sightseeings/SightSeeingsSlice'
import { getSightSeeingsAction } from '../../../services/client/sightseeings'
import ProgressLoader from '../../shared/ProgressLoader/ProgressLoader'

// SightSeeing component
export const SightSeeings = () => {
  
  const SightSeeingsData = useSelector(selectSightseeings);
  const loading = useSelector(selectSightseeingsLoading);
  
  useEffect(() => { 

      // Call the action to fetch sightseeings
      getSightSeeingsAction();
  
    }, []);

  return (
    <div>
    {/* call the add button component and set onclick */}
      <AddButton link={'/sightseeings/addsightseeings'}/>
    {
      loading ? <ProgressLoader size={'lg'}/> : 
      SightSeeingsData.length === 0 ? <center><h1 className='notFoundTitle'>No Sight Seeings Found</h1></center> : 
      SightSeeingsData.length > 0 &&  
      SightSeeingsData.map((sight, index) => (
        <SightSeeingsCard
          key={index}
          title={'Code'}
          image={sight.images.length > 0 ? sight.images[0].file : ''}
          name={sight.code}
          city={sight.city}
          detailsLink={'/sightseeings/details/' + sight.id}
          id={sight.id}
          type={'sightseeing'}
        />

    ))}
  
  </div>
  )
}

export default SightSeeings;