import { fetchAgents,fetchAgent, setAgent } from '../../features/agent/agentSlice';
import { store } from '../../features/store'
import { successNotification, errorNotification } from '../notification';



const CreateAgentApi = require('../../api/agent/index');
//api to fetch all agents.
export const getAgentsAction = () =>{
    store.dispatch(fetchAgents());
}


//fetching specific agent
export const fetchAgentAction =(id) =>{
    store.dispatch(fetchAgent(id));
}

export const setAgentAction = (payload) =>{
    store.dispatch(setAgent(payload));
}

export const addAgentAction = async (data, navigate) =>{

    const response = await CreateAgentApi.addAgentApi(data);

     //error handling.
     if(response.status)
     {
        successNotification(response.message);
        navigate('/agents')
     }
     else{
        errorNotification(response.message)
     }
 
     return response.data;
}

export const updateAgentAction = async (id, data, navigate) =>{

    const response = await CreateAgentApi.updateAgentApi(id, data);

     //error handling.
     if(response.status)
     {
        successNotification(response.message);
        navigate('/agents')
     }
     else{
        errorNotification(response.message)
     }
 
     return response.data;
}

export const deleteAgentAction = async (id) =>{

    const response = await CreateAgentApi.deleteAgentApi(id);

     //error handling.
     if(response.status)
     {
        successNotification(response.message);
         getAgentsAction();
     }
     else{
        errorNotification(response.message)
     }
 
     return response.data;
}