import axios from 'axios'
import { api_url } from '../../config';
import { Logout } from '../client/auth';
import { getAccessToken } from '../client/local-storage';
import { errorNotification } from '../client/notification';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const token = getAccessToken();
    if ( token ) {
        config.headers.Authorization =  `Bearer ${token}`;
    }     
    return config;
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
    }, function (error) {
    const pathname = window.location.pathname;
    if ( !pathname.includes('login') && !pathname.includes('signup') && error.response?.status === 401 ) {
        Logout();
        return;
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    errorNotification(error.response?.data?.message)
    return Promise.resolve(error.response?.data);
});


export const request = async (method, path, body) => {
    return await axios({
        method: method,
        url: `${api_url}/${path}`,
        data: body
    });
}