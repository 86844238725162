import React, { useEffect, useState } from 'react';
import CSS from "./Operations.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from 'react-router-dom';
import img from "../../../assets/icons/Rectangle 193.svg";
import SearchIcon from '../../../assets/icons/search.png';
import lineIcon from "../../../assets/icons/Line 52.svg";
import { selectQuotations } from '../../../services/features/quotationSlice/quotationSlice';
import { useSelector } from 'react-redux';
import deleteIcon from '../../../assets/icons/delete.svg';
import editIcon from '../../../assets/icons/edit.svg';
import Select from "react-select";
import { QuotationStatus } from '../../../services/client/utils';
import { deleteQuotationAction, fetchQuotationsAction, updateQuotationAction } from '../../../services/client/quotations';

const Operations = () => {
  const quotationsData = useSelector(selectQuotations) || [];
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching quotations...");
        await fetchQuotationsAction('status[]=final_payment_received');
        setLoading(false);
      } catch (err) {
        console.error("Error fetching quotations:", err);
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  console.log("Quotations Data: ", quotationsData);

  const setDateeFormat = (date) => {
    const newDate = new Date(date);
    const formattedDate = newDate.toLocaleDateString();
    return formattedDate;
  };

  const getQuotationStatus = (statusArray, statusValue) => {
    return statusArray.find(status => status.value === statusValue);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 30,
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: state.isSelected ? 0 : 0,
    }),
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  return (
    <div className={CSS.Container}>
      {/* <div className={CSS.TopContainer}>
        <img src={img} alt="search" className={CSS.MainImg} />
        <div className={CSS.ManiBox}>
          <div className={CSS.searchBarsContainer}>
            <img src={SearchIcon} alt="search" className={CSS.searchIcon} />
            <img src={lineIcon} className={CSS.Line1} />
            <input type="text" placeholder="Agent Name" className={CSS.searchBar} />
            <img src={SearchIcon} alt="search" className={CSS.searchIcon1} />
            <img src={lineIcon} className={CSS.Line2} />
            <input type="text" placeholder="Guest Name" className={CSS.searchBar} />
            <img src={SearchIcon} alt="search" className={CSS.searchIcon2} />
            <img src={lineIcon} className={CSS.Line3} />
            <input type="text" placeholder="Date" className={CSS.searchBar} />
            <button className={CSS.SearchButton}>Search</button>
          </div>
        </div>
      </div> */}
      <div className={CSS.tableContainer}>
        <table className={`${CSS["TableContainer"]} ${CSS["TableCustom"]}`}>
          <thead>
            <tr className={CSS.TableHead}>
              <th className={CSS.SerialNumber}>ID</th>
              <th className={CSS.TypeInput}>Quotation Date</th>
              <th className={CSS.TypeInput}>Agent Name</th>
              <th className={CSS.TypeInput}>No# of Pax</th>
              <th className={CSS.TypeInput}>Guest Name</th>
              <th className={CSS.TypeInput}>Status</th>
              <th className={CSS.TypeInput}>Payment Date</th>
              <th className={CSS.TypeInput}>Payment Method</th>
              {/* <th className={CSS.TypeInput}>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {quotationsData.map((quotation, index) => (
              <tr key={quotation.id || index} className={index % 2 === 0 ? CSS.backGroundColor : CSS.backGroundColor1}>
                <td className={CSS.SerialNumber}>
                  <Link to={`/quotation/details/${quotation.id}`} className={CSS.linkClass}>
                    {quotation.id}
                  </Link>
                </td>
                <td className={CSS.TypeInput}>{setDateeFormat(quotation.created_at)}</td>
                <td className={CSS.TypeInput}>{quotation.request?.agent?.company || ''}</td>
                <td className={CSS.TypeInput}>{quotation.request?.no_of_pax || '0'}</td>
                <td className={CSS.TypeInput}>{quotation.request?.customer_name || ''}</td>
                <td className={CSS.TypeInput}>
                  <Select
                    className={CSS.SelectContainer}
                    options={QuotationStatus}
                    value={getQuotationStatus(QuotationStatus, quotation.status)}
                    placeholder="Request ID"
                    styles={customStyles}
                  />
                </td>
                <td className={CSS.TypeInput}>{"12-05-2024 (dummy)"}</td>
                <td className={CSS.TypeInput}>{"Bank (dummy)"}</td>
                {/* <td className={CSS.actionsColumn}>
                  <div className={CSS.actionsContainer}>
                    <img src={deleteIcon} alt="delete" className={CSS.iconContainer} onClick={() => deleteQuotationAction(quotation.id)} />
                    <Link to={`/quotation/details/${quotation.id}`}><img src={editIcon} alt="edit" className={CSS.iconContainer} /></Link>
                  </div>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Operations;
