import React, { useState, useRef, useEffect } from 'react';
import './AddHotel.css'; 
import HotelImageIcon from '../../../../assets/icons/hotel-dummy.svg'
import RoomsCard from '../../../shared/RoomsCard/RoomsCard';
import {Cities as options} from '../../../../services/client/utils/index'
import { createHotel } from '../../../../services/client/hotel/create-hotel';
import { useNavigate } from 'react-router-dom'
import { selectRooms } from '../../../../services/features/hotels/hotelSlice';
import { useSelector } from 'react-redux';
import { setRoomsAction } from '../../../../services/client/hotel/create-hotel';

const AddHotel = () => {
  
  const [cityOptions, setCityOptions] = useState(options);
  const [selectedCity, setSelectedCity] = useState('');
  const [showCityOptions, setShowCityOptions] = useState(false);
  const [attachments, setAttachments] = useState();
  const fileInputRef = useRef(null);
  const cityInputRef = useRef(null);
  const [hotelName, setHotelName] = useState('');
  const [startContract, setStartContract] = useState('');
  const [endContract, setEndContract] = useState('');
  const rooms = useSelector(selectRooms);

  //checking for null values of the rooms.
  useEffect(() => {
    setRoomsAction([]);
  }, []);

  const navigate = useNavigate();

  // Function to handle city input change
  const handleCityInputChange = (e) => {
    const value = e.target.value;
    setCityOptions(options.filter(city => city.toLowerCase().includes(value.toLowerCase())));
    setSelectedCity(value);
    setShowCityOptions(true);
  };

  // Function to handle city option selection
  const handleCityOptionClick = (city) => {
    setSelectedCity(city);
    setShowCityOptions(false);
  };

  // Function to handle contract start date
  const handleContractStart = (e) => {
    setStartContract(e.target.value);
  };

  // Function to handle contract end date
  const handleContractEnd = (e) => {
    setEndContract(e.target.value);
  };


  //adding new hotel
  const saveHotel = () => {
    const data = {
      name: hotelName,
      city: selectedCity,
      rooms: rooms,
      contract_start: startContract,
      contract_expiry: endContract
    }
   createHotel(attachments, data, navigate);
  }

  // Function to handle image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setAttachments(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(file);
    }
  };

  const handleImageContainerClick = () => {
    fileInputRef.current.click();
  };

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setShowCityOptions(prevState => !prevState);
  };


  // Function to handle click outside dropdown
  const handleClickOutside = (e) => {
    if (cityInputRef.current && !cityInputRef.current.contains(e.target)) {
      setShowCityOptions(false);
    }
  };

  useEffect(() => {

    // Close dropdown when clicking outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className='container-addHotel'>
      {/* City and its searchbar */}
      <div className='container-for-aligning'>
        <div className='city-and-searchbar'>
          <div className='title-hotel-city'>City</div>
            <div className="dropdown" ref={cityInputRef}>
              <input
                type="text"
                className="citySearch"
                placeholder="Select city"
                value={selectedCity}
                onChange={handleCityInputChange}
                onClick={toggleDropdown}
              />
              {showCityOptions && (
                <div className="dropdown-content">
                  {cityOptions.map(city => (
                    <div key={city} onClick={() => handleCityOptionClick(city)}>{city}</div>
                  ))}
                </div>
              )}
            </div>
        </div>
        {/* Hotel and its searchbar */}
        <div className='hotel-and-searchbar'>
          <div className='title-hotel'>Hotel Name</div> 
          <input type="text" className="hotel-search" placeholder="Hotel Name" onChange={(e) => {setHotelName(e.target.value)}}  />
        </div>
      </div>
      {/* Setting the image containers */}
      <div className='container-for-aligning'>
        <div className='image-container'>
        <div className='title-hotel-city'>Hotel Image</div>
          <div className='input-image' onClick={handleImageContainerClick}>
            <label htmlFor="hotelImageInput">
                <img src={HotelImageIcon} alt='Hotel Placeholder' className='input-image-icon'/>
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={fileInputRef}
              style={{ display: 'none'}}
            />
          </div>
        </div>

        <div className='timeContainers'>
          <div className='title-hotel-city'>Contract</div>
            <div className='date-container'>
              <div className='inputs-column'>
                <div className='contract-titles'>Contract Start</div>
                  <input type="date" className="date-search" onChange={handleContractStart} />
                </div>
                <div className='inputs-column'>
                <div className='contract-titles'>Contract Expiry</div>
                  <input type="date" className="date-search" onChange={handleContractEnd} />
                </div>
              </div>
          </div>
        </div>

      {/* Rooms and its searchbar */}
       <div className='rooms-and-searchbar'>
          <div className='title-hotel-city'>Rooms</div>
        </div>

      {/* Add Rooms Card and Add Button */}
      <div className='room-type-container'>
        <div className='add-rooms-card'>
          <RoomsCard />
        </div>
      </div>
      
      {/* Save Button */}
      <div className='save-button'>
        <button className='save-button-text' onClick={saveHotel}>Save</button>
      </div>
    </div>
  );
}

export default AddHotel;
