import {request} from '../request'
import { defaultAPIParams, objectToQueryString } from '../../client/utils'
//get SightSeeings api
export const getSightSeeingsApi = async (params = {}) => {
    params = { ...defaultAPIParams, ...params}
    params = '?' + objectToQueryString(params)
    return await request('get', 'sightseeings'+params)
}

export const getSightSeeing = async (id) => {
    return await request('get', `sightseeings/${id}`)
}

//delete SightSeeing api
export const deleteSightSeeingApi = async (id) => {
    return await request('delete', `sightseeings/${id}`)
}