import { request } from "../request";
import { defaultAPIParams, objectToQueryString } from '../../client/utils'

export const createTransferApi = async (data) => {
  return await request("post", "transfers", data);
};

export const getTransfersApi = async (params = {}) => {
  params = { ...defaultAPIParams, ...params}
  params = '?' + objectToQueryString(params)
  return await request("get", "transfers"+params);
};

export const getTransferApi = async (id) => {
  return await request('get',`transfers/${id}`)
}

export const updateTransferApi = async (id, data) => {
  return await request('put',`transfers/${id}`, data)
}
