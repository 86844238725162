import React, { useState } from "react";
import CSS from "./OperationsDetails.module.css";
import RequestIcon from "../../../../assets/icons/OperationsIcon.svg";
import { useNavigate } from "react-router-dom";
import CalenderIcon from "../../../../assets/icons/Group.svg"
import BookedIcon from "../../../../assets/icons/copy-success.svg"

const OperationsDetails = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const handleBackOperations = () => {
    navigate("/Operations");
  };

  return (
    <div>
      <div className={CSS.Maincontainer}>
        <div className={CSS.FirstContainer}>
          <div className={CSS.NoContainer}>
            <label className={CSS.NO}>No.</label>
            <p className={CSS.NoInput}>ddddddd</p>
          </div>
          <div className={CSS.BookingContainer}>
            <label className={CSS.Booking}>Booking</label>
            <p className={CSS.BookingInput}>ddddddd</p>
          </div>
          <div className={CSS.GuestContainer}>
            <label className={CSS.Guest}>Guest Name</label>
            <p className={CSS.GuestInput}>ddddddd</p>
          </div>
        </div>
        <div className={CSS.SecondConatiner}>
          <div className={CSS.ContactContainer}>
            <label className={CSS.Contact}>Contact</label>
            <p className={CSS.ContactInput}>ddddddd</p>
          </div>
          <div className={CSS.DateContainer}>
            <label className={CSS.Date}>Date</label>
            <p className={CSS.DateInput}>ddddddd</p>
          </div>
          <div className={CSS.NoofpaxContainer}>
            <label className={CSS.Noofpax}>No of pax</label>
            <p className={CSS.NoofpaxInput}>ddddddd</p>
          </div>
        </div>
      </div>
      <div className={`${CSS["CustomTableContainer"]}`}>
        <table className={`${CSS["TableContainer"]} ${CSS["TableCustom"]}`}>
          <thead>
            <tr className={CSS.TableHead}>
            <th className={CSS.ID}>Sr</th>
              <th className={CSS.ResourceDetails}>Guest Name</th>
              <th className={CSS.ResourceDetails}>Guest Phone </th>
              <th className={CSS.ResourceDetails}>No of pax</th>
              {/* <th className={CSS.ResourceDetails}>Check In</th>
              <th className={CSS.ResourceDetails}>Check Out</th>
              <th className={CSS.ResourceDetails}>Date</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={CSS.ID}>B112</td>
                <td className={CSS.ResourceDetails}>Resource</td>
                <td className={CSS.ResourceDetails}>Resource Details</td>
                <td className={CSS.ResourceDetails}>05</td>
                {/* <td className={CSS.ResourceDetails}>Pick Up</td>
                <td className={CSS.ResourceDetails}>Drop Off</td>
                <td className={CSS.ResourceDetails}>10/01/2024</td> */}
            </tr>
            <tr>
              <td className={CSS.ID}>B112</td>
                <td className={CSS.ResourceDetails}>Resource</td>
                <td className={CSS.ResourceDetails}>Resource Details</td>
                <td className={CSS.ResourceDetails}>05</td>
                {/* <td className={CSS.ResourceDetails}>Pick Up</td>
                <td className={CSS.ResourceDetails}>Drop Off</td>
                <td className={CSS.ResourceDetails}>10/01/2024</td> */}
            </tr>   
          </tbody>
        </table>
      </div>
      <button className={CSS.BackButton} onClick={handleBackOperations}>
        BACK
      </button>
    </div>
  );
};

export default OperationsDetails;
