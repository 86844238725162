import React from "react";
import { Outlet } from 'react-router-dom';

import { Container } from "react-bootstrap";


const AuthLayout = () => (
    <>
        <Container>
            <Outlet />
        </Container>
    </>
);

export default AuthLayout;