import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTransfersApi, getTransferApi } from "../../api/transfers/index";

export const fetchTransfers = createAsyncThunk(
  "transfers/fetchTransfers",
  async () => {
    const response = await getTransfersApi();
    return response;
  }
);

export const fetchTransfer = createAsyncThunk(
  "transfer/fetchTransfer",
  async (id) => {
    const response = await getTransferApi(id);
    return response.data;
  }
);

const initialState = {
  transfers: [],
  loading: false,
  error: null,
  transfer: {
    id: null,
    selectedFromType: "",
    selectedToType: "",
    fromValue: "",
    toValue: "",
    carInput: "",
    suvInput: "",
    vanInput: "",
    ferryInput: "",
    boatInput: "",
  },
};

const transferSlice = createSlice({
  name: "transfers",
  initialState,
  reducers: {
    setTransfer: (state, action) => {
      state.transfer = { ...state.transfer, ...action.payload };
    },
  
    resetState: (state) => initialState,
  
    setcarInput: (state, action) => {
      state.transfer.carInput = action.payload;
    },
    setsuvInput: (state, action) => {
      state.transfer.suvInput = action.payload;
    },
    setvanInput: (state, action) => {
      state.transfer.vanInput = action.payload;
    },
    setferryInput: (state, action) => {
      state.transfer.ferryInput = action.payload;
    },
    setBoatInput: (state, action) => {
      state.transfer.boatInput = action.payload;
    },
  },
  

  extraReducers: (builder) => {
    builder
      .addCase(fetchTransfer.fulfilled, (state, action) => {
        state.loading = false;
        const transfer = action.payload;
        state.transfer = {
          id: transfer.id,
          selectedFromType: transfer.from_type,
          selectedToType: transfer.to_type,
          fromValue: transfer.from,
          toValue: transfer.to,
          carInput: transfer.types.find((type) => type.type === 0)?.cost || 0,
          suvInput: transfer.types.find((type) => type.type === 1)?.cost || 0,
          vanInput: transfer.types.find((type) => type.type === 2)?.cost || 0,
          ferryInput: transfer.types.find((type) => type.type === 3)?.cost || 0,
          boatInput: transfer.types.find((type) => type.type === 4)?.cost || 0
        }
      })
      .addCase(fetchTransfers.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          const { currentPage, lastPage, perPage, total, data } =
            action.payload.data;

          // Update metadata
          state.currentPage = currentPage;
          state.lastPage = lastPage;
          // Check if data is an array before mapping
          if (Array.isArray(data)) {
            // Transform the array of transfers into the object
            const transferArray = data.map((transfer) => ({
              id: transfer.id,
              from: transfer.from,
              to: transfer.to,
              types: {
                carInput:
                  transfer.types.find((type) => type.type == 0).cost || 0,
                suvInput:
                  transfer.types.find((type) => type.type == 1).cost || 0,
                vanInput:
                  transfer.types.find((type) => type.type == 2).cost || 0,
                ferryInput:
                  transfer.types.find((type) => type.type == 3).cost || 0,
                boatInput:
                  transfer.types.find((type) => type.type == 4).cost || 0,
              },
            }));

            // Concatenate the new array with the existing transferList
            state.transfers = transferArray;
          } else {
            console.error(
              "Invalid data format received. Expected data to be an array:",
              data
            );
          }
        } else {
          console.error("Invalid data format received:", action.payload);
        }
      });
      
  },
  
});

export const {
  transfer,
  selectedFromType,
  selectedToType,
  fromValue,
  toValue,
  carInput,
  suvInput,
  vanInput,
  ferryInput,
  boatInput,
  setcarInput,
  setsuvInput,
  setvanInput,
  setferryInput,
  setBoatInput,
  setTransfer,
} = transferSlice.actions;

export const selectTransfer = (state) => state.transfer;
export const selectTransfers = (state) => state.transfers;
export const { resetState } = transferSlice.actions;
export const selectCurrentPage = (state) => state.transfers.currentPage;
export const selectLastPage = (state) => state.transfers.lastPage;

export default transferSlice.reducer;
