import {
  setTransfer,
  setselectedFromType,
  setselectedToType,
  setfromValue,
  settoValue,
  setcarInput,
  setsuvInput,
  setvanInput,
  setferryInput,
  setBoatInput,
  fetchTransfer,
} from "../../features/transfers/transferSlice";
import {
  errorNotification,
  successNotification,
} from "../notification";
import { store } from "../../features/store";
import { getTransferApi, createTransferApi, updateTransferApi } from "../../api/transfers/index";
import { getAirportsAction } from "../airports";
import { getHotelsAction } from "../hotel";
import { getSightSeeingsAction } from "../sightseeings";
import { fetchTransfers } from "../../features/transfers/transferSlice";


export const createTransferAction = async (data, navigate) => {
  console.log('dfdsaf')
  const response = await createTransferApi(data);

  //error handling.
  if (response.status) {
    successNotification(response.message);
    navigate("/transfers");
  } else {
    errorNotification(response.message);
  }

  return response.data;
};

export const GetTransferApi = (data) =>{
  store.dispatch(getTransferApi(data));
}

export const updateTransferAction = async ( data, navigate) => {

  const response = await updateTransferApi(data.id, data);

  //error handling.
  if(response.status)
  {
      successNotification(response.message);
      navigate('/transfers')
  }
  else{
      errorNotification(response.message)
  }
  
}

export const fetchTransferAction = (id) =>{
  setTransfer("");
  getResourcesDataAction();
  store.dispatch(fetchTransfer(id));
}

export const setTransferValuesAction = (data) => {
  store.dispatch(setTransfer(data));
};

export const getResourcesDataAction = () => {
  getAirportsAction();
  getHotelsAction();
  getSightSeeingsAction();
}

export const setselectedFromTypeAction = (data) => {
  store.dispatch(setTransfer(data));
};

export const setselectedToTypeAction = (data) => {
  store.dispatch(setTransfer(data));
};

export const setfromTypeAction = (data) => {
  store.dispatch(setTransfer(data));
};

export const settoTypeAction = (data) => {
  store.dispatch(setTransfer(data));
};
export const setcarInputPriceAction = (data) => {
  store.dispatch(setcarInput(data));
};
export const setsuvInputPriceAction = (data) => {
  store.dispatch(setsuvInput(data));
};
export const setvanInputPriceAction = (data) => {
  store.dispatch(setvanInput(data));
};
export const setferryInputPriceAction = (data) => {
  store.dispatch(setferryInput(data));
};
export const setBoatInputPriceAction = (data) => {
  store.dispatch(setBoatInput(data));
};

export const getTransfersAction = () => {
  store.dispatch(fetchTransfers());
}