import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAirportsApi } from "../../api/airports/index";

export const fetchAirports = createAsyncThunk(
  "airports/fetchAirports",
  async () => {
    const response = await getAirportsApi();
    return response.data;
  }
);

const initialState = {
  airports: []
};

const airportSlice = createSlice({
  name: "airports",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAirports.fulfilled, (state, action) => {
        if (action.payload ) {
            state.airports = action.payload
        }
      });
      
  },
  
});

export const selectAirports = (state) => state.airports.airports;
export default airportSlice.reducer;
