import Spinner from 'react-bootstrap/Spinner';
import './Progressloader.css';

function ProgressLoader({size}) {

  return (
    <div className="center-progressbar">
      <Spinner animation="border" size={size} />
    </div>
    )
}

export default ProgressLoader;