import React from 'react'
import './WorkunderProgress.css'
import WorkInProgress from '../../../assets/icons/work-in-progress.png'

const WorkUnderProgress = () => {
  return (
    <div className='containerForWorkUnderProgress'>
        <div className='centerredContainer'>
            <img src={WorkInProgress} alt='WorkInProgress' />
            Hello, this page is under construction. Please visit later.
        </div>
    </div>
  )
}

export default WorkUnderProgress
