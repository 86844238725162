import React from 'react'
import './Card.css'
import { Link, useNavigate } from 'react-router-dom'
import deleteIcon from "../../../assets/icons/delete.svg"
import editIcon from "../../../assets/icons/edit.svg"
import { deleteHotelAction, getHotelsAction } from '../../../services/client/hotel'
import { deleteSightSeeingAction, getSightSeeingsAction } from '../../../services/client/sightseeings'

const Card = ({title, image, name, city, detailsLink, id, type}) => {

    // const navigate = useNavigate();

    const handleOnclickDeleteButton = () => {

        if(type === 'hotel'){
            deleteHotelAction(id);
            getHotelsAction();
        }
        else if(type === 'sightseeing'){
            deleteSightSeeingAction(id);
        }
    }

  return (
    <div className="hotel-card">
        
        
        {/*Hotel Image*/}
        <img src={image} alt="image" className="hotel-image" />
       
        {/*delete icon*/}
        <div className='deleteContainer' onClick={handleOnclickDeleteButton}>
            <img src={deleteIcon} alt="delete" className='deleteImage'/>
        </div>
        
        {/*edit icon*/}
        <div className='editContainer'>
            
            <Link to = {detailsLink}>
                <img src={editIcon} alt="edit" className='editImage'/>
            </Link>
            {/* onClick={handleOnclickEditButton} */}
        </div>

        <div className="hotel-details">

            {/*title*/}
            <p className="title">{title}</p>

            {/*edit icon*/}

            {/*button*/}
            <button className="button">{name.toUpperCase()}</button>

            {/*title*/}
            <p className="title">City</p>

            {/*button*/}
            <button className="button">{city.toUpperCase()}</button>

            {/*button*/}
            <Link to = {detailsLink} className="details">Check Details</Link>
        </div>
  </div>
  )
}

export default Card;