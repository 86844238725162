import {request} from '../request'

export const postbookingrequestApi = async (data) =>{
    return await request('post', 'bookingrequests', data);
}

export const getbookingrequestsAPi = async () => {
    return await request('get', 'bookingrequests');
}

export const getBookingRequestAPi = async (id) => {
    return await request('get', `bookingrequests/${id}`);
}

export const putbookingrequestdetailsApi = async (id,data) => {
  return await request('put', `bookingrequests/${id}`,data);
  };

export const deleteBookingRequestApi = async (id) =>{
    return await request('delete',`bookingrequests/${id}`);
};