import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAgentAPI,getAgentsApi,deleteAgentApi } from "../../api/agent/index";


export const fetchAgents = createAsyncThunk(
  "agents/fetchAgents",
  async () => {
    const response = await getAgentsApi();
    return response.data.data;
  }
);

export const fetchAgent = createAsyncThunk(
  "agents/fetchAgent",
  async (id) => {
    const response = await getAgentAPI(id);
    return response.data;
  }
);
export const deleteAgent = createAsyncThunk(
  "agents/deleteAgent",
  async (id) => {
    const response = await deleteAgentApi(id);
    return response.data;
  }
);


const agentSlice = createSlice({
  name: "agents",
  initialState: {
    agent: {
      name: "",
      phone: "",
      address: "",
      country: "",
      created_at: "",
    },
    agents: [],
  },
  reducers: {
    setAgent: (state, action) => {
      state.agent = {...state.agent, ...action.payload};

    },

  },

  extraReducers: (builder) => {

    builder
    // case for fetching the agents.
    .addCase(fetchAgents.fulfilled, (state, action) => {
      state.agents = action.payload;
    })

    // case for fetching a specific agent.
    .addCase(fetchAgent.fulfilled, (state, action) => {
      state.agent = action.payload;
    })
    .addCase(deleteAgent.fulfilled, (state, action) => {
      state.agents = state.agents.filter((agent) => agent.id !== action.payload.id);
    })
}
});


export const selectAgentsStatus = (state) => state.agents.status;
export const selectAgentsError = (state) => state.agents.error;
export const selectAgents = (state) => state.agents.agents;
export const selectAgent =(state) => state.agents.agent;
export const { setAgent } = agentSlice.actions;

export default agentSlice.reducer;
