import {
  fetchBookingrequests,
  fetchBookingRequests,
  fetchBookingrequest,
  updateBookingRequestDetails,
  setAgentName,
  setAgentId,
  setCustomerName,
  setCustomerPhone,
  setNoOfPax,
  setTravelDate,
  resetState,
  } from "../../features/bookingrequest/bookingrequestSlice";
import { postbookingrequestApi,putbookingrequestdetailsApi,deleteBookingRequestApi } from "../../api/bookingrequest/index";
import { errorNotification, successNotification } from "../notification";
import { store } from "../../features/store";
const CreateBookingRequestApi = require('../../api/bookingrequest/index')

export const getBookingrequestsAction = () => {
  store.dispatch(fetchBookingRequests());
};
export const getBookingrequestAction = (id) => {
  store.dispatch(fetchBookingrequest(id));
};

export const updateBookingrequestdetailsAction = async(id,data,navigate) => {
  const response = await putbookingrequestdetailsApi(id,data)

  //error handling
  if(response.status) {
    successNotification(response.message)
    navigate("/request");
  } else {
    errorNotification(response.message);
  }

};


export const createBookingRequestAction = async (data, navigate) => {
  const response = await postbookingrequestApi(data);

  //error handling.
  if (response.status) {
    successNotification(response.message);
    navigate("/request");
  } else {
    errorNotification(response.message);
  }

  return response.data;
};

export const setrequestdetailsAction =  (id) =>{
  store.dispatch(updateBookingRequestDetails(id));
}

export const setagentidAction = (data)=>{
  store.dispatch(setAgentId(data));
}

export const setcustomernameAction = (data) =>{
  store.dispatch(setCustomerName(data));
}

export const setcustomerphoneAction = (data) =>{
  store.dispatch( setCustomerPhone(data));
}
export const setnoofpaxAction = (data) =>{
  store.dispatch(setNoOfPax(data));
}
export const settraveldateAction = (data) =>{
  store.dispatch(setTravelDate(data));
}
export const setAgentnameAction=(data)=>{
  store.dispatch(setAgentName(data));
}
export const resetStateAction = () => {
  store.dispatch(resetState());
};
export const deleteAction= async (id)=>{
 const response = await CreateBookingRequestApi.deleteBookingRequestApi(id);
     //error handling.
     if(response.status)
     {
        successNotification(response.message);
        getBookingrequestsAction();
     }
     else{
        errorNotification(response.message)
     }
 
     return response.data; 
 
}