import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CSS from './QuotationDetails.module.css';
import BackgroundImage from '../../../../assets/icons/Quotation-Background.png';
import gdLogo from '../../../../assets/global-destination-logo.svg';
import emailLogo from '../../../../assets/icons/email.png';
import phoneLogo from '../../../../assets/icons/contact.png';
import websiteLogo from '../../../../assets/icons/web-site.png';
import QuotationDetailCard from '../../../shared/QuotationDetailCard/QuotationDetailCard';
import GD_Logo from '../../../../assets/global-destination-logo.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getBookingrequestsAction, getBookingrequestAction } from '../../../../services/client/bookingrequest/index';
import { selectBookingRequests, selectBookingRequest } from '../../../../services/features/bookingrequest/bookingrequestSlice';
import { createQuotationAction, fetchQuotationAction } from '../../../../services/client/quotations';
import { selectQuotation } from '../../../../services/features/quotationSlice/quotationSlice';

const Currencies = [
  { value: 'USD', label: 'USD' },
  { value: 'THB', label: 'THB' },
];

const QuotationDetails = (id) => {
  const quotationID = useParams();
  // const navigate = useNavigate();
  const bookingRequestData = useSelector(selectBookingRequest);
  const bookingrequestsData = useSelector(selectBookingRequests);
  const quotationData = useSelector(selectQuotation);

  const [selectedRequestId, setSelectedRequestId] = useState(null);

  const requestIdOptions = bookingrequestsData.map(item => ({
    value: item.id.toString(),
    label: item.id.toString(),
  }));

  useEffect(() => {
    // Fetching the booking requests
    getBookingrequestsAction();

    // Fetching the single quotation details
    fetchQuotationAction(quotationID.id);
  }, []);

  useEffect(() => {
    if (quotationData && quotationData.request_id) {
      const matchedOption = requestIdOptions.find(option => option.value === quotationData.request_id.toString());
      setSelectedRequestId(matchedOption);
    }
  }, [quotationData, requestIdOptions]);

  const handleQuotationChange = (selectedOption) => {
    setSelectedRequestId(selectedOption);
    getBookingrequestAction(selectedOption.value);
  };

  const [today, setToday] = useState('');

  useEffect(() => {
    const currentDate = new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    setToday(currentDate);
  }, []);

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    return `${month}/${day}/${year}`;
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 30,
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: state.isSelected ? 0 : 0,
    }),
  };

  const handleNewQuotation = () => {

    const updatedQuotationItems = quotationData.map(item => {
      if (item.resource_type === 0) {
        return {
          
          resource_type: item.resource_type,
          resource_id: item.resource_id,
          cost_per_pax: item.cost_per_pax,
          date: item.date,
          item_accommodation: {
            no_of_nights: item.item_accommodation.no_of_nights,
            check_in: item.item_accommodation.check_in,
            check_out: item.item_accommodation.check_out,
          },
          rooms: item.rooms.map(room => ({
            room_id: room.room_id,
            no_of_rooms: room.no_of_rooms,
          })),
        };

      } else {
        return {
          resource_type: item.resource_type,
          resource_id: item.resource_id,
          resource_id_value: item.resource_id_value,
          cost_per_pax: item.cost_per_pax,
          date: item.date,
        };
      }
    });

    const quotationDataToSend = {
      request_id: bookingRequestData.id,
      items: updatedQuotationItems,
    };

    // createQuotationAction(quotationDataToSend, navigate);
  };

  return (
    <div className={CSS.NewQuotationMainContainer}>
      <div className={CSS.NewQuotationHeader} style={{ backgroundImage: `url(${BackgroundImage})` }}>
        {/* Header area */}
        <div className={CSS.HeadingForQuotation}>
          <label className={CSS.HeadingLabel}>QUOTATION</label>
        </div>

        <div className={CSS.LogoContainer}>
          <img src={gdLogo} alt="Global Destination Logo" className={CSS.Logo} />
        </div>
      </div>

      {/* Half form */}
      <div className={CSS.NewQuotationFormContainer}>
        {/* Inputs details */}
        <div className={CSS.FormDataContainer}>
          <div className={CSS.DataContainer}>
            <label className={CSS.Label}>Quote to:</label>
            {/* <Select
              className={CSS.dropdownContainer}
              options={requestIdOptions}
              value={selectedRequestId}
              onChange={handleQuotationChange}
              placeholder="Request ID"
              styles={customStyles}
            /> */}
          </div>

          <div className={CSS.DataContainer}>
            <div className={CSS.AgentNameAndGuestNameContainer}>
              <label className={CSS.nameTitles}>Agent Name</label>
              <input
                type="text"
                className={CSS.inputField}
                value={bookingRequestData.agent ? bookingRequestData.agent.company : 'Agent Name'}
                readOnly
              />
            </div>

            <div className={CSS.AgentNameAndGuestNameContainer}>
              <label className={CSS.nameTitles}>Guest Name</label>
              <input type="text" className={CSS.inputField} value={bookingRequestData.customer_name ? bookingRequestData.customer_name : 'Customer Name'} readOnly />
            </div>
          </div>

          <div className={CSS.DataContainer}>
            <div className={CSS.AgentNameAndGuestNameContainer}>
              <label className={CSS.nameTitles}>Travel Date</label>
              <input type="text" className={CSS.inputField} value={bookingRequestData.travel_date ? formatDate(bookingRequestData.travel_date) : 'Travel Date'} readOnly />
            </div>

            <div className={CSS.AgentNameAndGuestNameContainer}>
              <label className={CSS.nameTitles}>No# of Pax</label>
              <input type="text" className={CSS.inputField} value={bookingRequestData.no_of_pax ? bookingRequestData.no_of_pax : 'Pax'} readOnly />
            </div>
          </div>
        </div>
        {/* Contact details */}
        <div className={CSS.FormDataContainer}>
          <div className={CSS.contactNumberContainer}>
            <div className={CSS.number}>
              <label>+66 99 358 8215</label>
              <label>+66 91 013 6242</label>
            </div>
            <img src={phoneLogo} alt="Contact Icon" className={CSS.phoneIcon} />
          </div>

          {/* Email information */}
          <div className={CSS.contactNumberContainer}>
            <div className={CSS.number}>
              <label>globaldestination@gmail.com</label>
            </div>
            <img src={emailLogo} alt="email" className={CSS.iconsLogo} />
          </div>

          {/* Website information */}
          <div className={CSS.contactNumberContainer}>
            <div className={CSS.number}>
              <label>www.globaldestination.online</label>
            </div>
            <img src={websiteLogo} alt="website logo" className={CSS.iconsLogo} />
          </div>

          {/* Today's date */}
          <div className={CSS.contactNumberContainer}>
            <div className={CSS.number}>
              <label className={CSS.dateTitle}>Date: {today}</label>
            </div>
          </div>
        </div>
      </div>

      {/* Quotation details */}
      <div className={CSS.tableContainer}>
        <table className={`${CSS["TableContainer"]} ${CSS["TableCustom"]}`}>
          <thead>
            <tr className={CSS.TableHead}>
              <th className={CSS.SerialNumber}>SNo#</th>
              <th className={CSS.tourDetails}>Tour Details</th>
              <th className={CSS.particulars}>Particulars</th>
              <th className={CSS.Date}>Date</th>
              <th className={CSS.Price}>Price</th>
              <th className={CSS.Price}>Cost Per Pax</th>
              <th className={CSS.actionsHeading}>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="7">
                {
                  <QuotationDetailCard />
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Total amount */}
      {/* Quotation details */}
      <div className={CSS.tableContainer}>
        <div className={CSS.TotalAmountContainer}>
          <table className={`${CSS["TableContainer"]} ${CSS["TableCustom"]}`}>
            <thead>
            </thead>
            <tbody>
              <tr>
                <th className={CSS.amountTableData}>Sub Total</th>
                <th className={CSS.amountTableData}>1000</th>
              </tr>
              <tr>
                <th className={CSS.background}>Charges</th>
                <th className={CSS.background}>300</th>
              </tr>
              <tr>
                <th className={CSS.amountTableData}>Total per PAX</th>
                <th className={CSS.amountTableData}>10</th>
              </tr>
              <tr>
                <th className={CSS.background}></th>
                <th className={CSS.background}>
                  <Select
                    className={CSS.currencyDropdownContainer}
                    options={Currencies}
                    placeholder="USD/THB"
                    styles={customStyles}
                  />
                </th>
              </tr>
              <tr>
                <th className={CSS.amountTableData}>Total per PAX after currency change</th>
                <th className={CSS.amountTableData}>1310</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Signature */}
      <div className={CSS.SignatureContainer}>
        <div className={CSS.logoAndSignContainer}>
          <h1 className={CSS.SignatureTitle}>Signature</h1>
          <img src={GD_Logo} alt="Global Destination Logo" className={CSS.SignatureLogo} />
        </div>

        <p className={CSS.noteClass}><b>Note:</b> In case of payment in THB / USD, remittance charges are not applicable.</p>
      </div>

      {/* Buttons */}
      <div className={CSS.ButtonsContainer}>
        <button className={CSS.saveButton} onClick={handleNewQuotation}>Save</button>
        <button className={CSS.printButton}>View Report</button>
      </div>
    </div>
  );
};

export default QuotationDetails;
