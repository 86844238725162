import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import './sidebar.css';
import Logo from '../../../assets/global-destination-logo.svg';

function Sidebar({ items, bottom_icons }) {

  const navigate = useNavigate();

  const handleLogout = () => {
    
      window.localStorage.clear(); //clearing token from local storage
      navigate('/login');
  };

  return (
    <div className='sidebar'>
      <div className="logo-container" >
        <img src={Logo} alt="Logo" className="logo" />
      </div>
      {
        items.map(item => (
          <NavLink
            key={item.link}
            to={item.link}
            className={({ isActive }) =>
              isActive ? 'sidebar-item active' : 'sidebar-item'
            }
          >
            <img src={item.icon} alt={item.title} className="sidebar-icon" />
            {item.title}
          </NavLink>
        ))
      }

<div className="divider">
  {bottom_icons.map(data => (
    <NavLink
      key={data.link}
      to={data.link}
      className={({ isActive }) =>
        isActive ? 'bottom-icon active' : 'bottom-icon'
      }
    >
      <img src={data.icon} alt={data.title} className="sidebar-icon" />
      {data.title === "Logout" ? (
        <span onClick={handleLogout}>{data.title}</span>
      ) : (
        data.title
      )}
    </NavLink>
  ))}
</div>


    </div>
  );
}

export default Sidebar;
