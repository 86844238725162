import React, { useEffect } from "react";
import CSS from "./CreateTransfer.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  transfer,
  setTransfer,
  setcarInput,
  setsuvInput,
  setvanInput,
  setferryInput,
  setBoatInput,
  resetState, 
} from "../../../services/features/transfers/transferSlice";
import From from "../../shared/Fromto/From";
import To from "../../shared/Fromto/To";
import SelectFrom from "../../shared/Fromto/SelectFrom";
import SelectTo from "../../shared/Fromto/SelectTo";
import { TravelType, FromPlace } from "../../../services/client/utils";
import { createTransferAction, getResourcesDataAction } from "../../../services/client/transfers/index";
import { useNavigate } from "react-router-dom";

const CreateTransfer = () => {
  const dispatch = useDispatch();
  const transferData = useSelector((state) => state.transfers.transfer);
  const loading = useSelector((state) => state.transfers.loading);
  const error = useSelector((state) => state.transfers.error);
  const navigate = useNavigate();

  // Add a useEffect to reset the state when the component mounts
  useEffect(() => {
    // Dispatch the reset action
    dispatch(resetState());
    getResourcesDataAction();
  }, []);

  const handleSubmit = () => {
    const data = {
      from_type: transferData.selectedFromType,
      to_type: transferData.selectedToType,
      from: transferData.fromValue,
      to: transferData.toValue,
      types: [
        {
          type: 0,
          cost: transferData.carInput || 0,
        },
        {
          type: 1,
          cost: transferData.suvInput || 0,
        },
        {
          type: 2,
          cost: transferData.vanInput || 0,
        },
        {
          type: 3,
          cost: transferData.ferryInput || 0,
        },
        {
          type: 4,
          cost: transferData.boatInput || 0,
        },
      ],
    };

    createTransferAction(data, navigate);
  };

  const handleFromTypeSelect = (value) => {
    const selectedValue = FromPlace.find((item) => item.value === value);
    dispatch(
      setTransfer({
        selectedFromType: selectedValue.type,
        fromValue: selectedValue ? selectedValue.value : "",
      })
    );
  };

  const handleToTypeSelect = (value) => {
   
    const selectedValue = FromPlace.find((item) => item.value === value);
    dispatch(
      setTransfer({
        selectedToType: selectedValue.type,
        toValue: selectedValue ? selectedValue.value : "",
      })
    );
  };

  // Callback function to update FromValue
  const handleFromValueChange = (item) => {
    dispatch(setTransfer({ fromValue: item.name }));
  };

  // Callback function to update ToValue
  const handleToValueChange = (item) => {
    dispatch(setTransfer({ toValue: item.name }));
  };
  return (
    <div>
      <div>
        <div>
          <div className={CSS.From}>
            <div>
              <h6 className={CSS.FromHeader}>From</h6>
              <div className={CSS.FromPlaceContainer}>
                <div>
                  <From
                    onSelectChange={handleFromTypeSelect}
                    onFromValueChange={handleFromValueChange}
                  />
                </div>
              </div>
            </div>
            <div>
              <SelectFrom
                selectedType={transferData.fromValue}
                onFromValueChange={handleFromValueChange}
              />
            </div>
          </div>
          <div className={CSS.To}>
            <div className={CSS.FromTitleContainer}>
              <h6 className={CSS.ToHeader}>To</h6>
              <div className={CSS.FromPlaceContainer}>
                <div>
                 
                  <To
                    onSelectChange={handleToTypeSelect}
                    onToValueChange={handleToValueChange}
                  />
                  
                </div>
              </div>
            </div>
            <div>
              <SelectTo
                selectedType={transferData.toValue}
                onToValueChange={handleToValueChange}
              />
            </div>
          </div>
          <div className={CSS.FromBoxTo}>
            <div className={CSS.FromBox}>{transferData.fromValue}</div>
            <h6 className={CSS.ToTitle}>To</h6>
            <div className={CSS.ToBox}>{transferData.toValue}</div>
          </div>
          <div className={CSS.TransType}>
            <div className="d-flex flex-column gap-2">
            {
            TravelType.filter((item) => ([0,1,2].includes(item.type))).map((type) => (
              <div className='d-flex align-items-center gap-1'
                key={type.type}>
                <label>
                  {type.name}
                </label>
                <input
                  type="text"
                  id={`${type.name.toLowerCase()}Input`}
                  name="Input"
                  value={
                    type.name === "By Car"
                      ? transferData.carInput
                      : type.name === "By Suv"
                      ? transferData.suvInput
                      : type.name === "By Van"
                      ? transferData.vanInput
                      : type.name === "By Ferry"
                      ? transferData.ferryInput
                      : type.name === "By Boat"
                      ? transferData.boatInput
                      : ""
                  }
                  onChange={(e) => {

                    switch (type.name) {
                      case "By Car":
                        dispatch(setcarInput(e.target.value));
                        break;
                      case "By Suv":
                        dispatch(setsuvInput(e.target.value));
                        break;
                      case "By Van":
                        dispatch(setvanInput(e.target.value));
                        break;
                      case "By Ferry":
                        dispatch(setferryInput(e.target.value));
                        break;
                      case "By Boat":
                        dispatch(setBoatInput(e.target.value));
                        break;
                      default:
                        break;
                    }
                  }}
                  className={
                    type.name === "By Ferry" || type.name === "By Boat"
                      ? CSS.FerryBoatInput
                      : CSS.TransInput
                  }
                />
                <br />
              </div>
            ))}
            </div>
            <div className="d-flex flex-column gap-2">
            {
            TravelType.filter((item) => ([3,4].includes(item.type))).map((type) => (
              <div className='d-flex align-items-center gap-1'
                key={type.type}
              >
                <label>
                  {type.name}
                </label>
                <input
                  type="text"
                  id={`${type.name.toLowerCase()}Input`}
                  name="Input"
                  value={
                    type.name === "By Car"
                      ? transferData.carInput
                      : type.name === "By Suv"
                      ? transferData.suvInput
                      : type.name === "By Van"
                      ? transferData.vanInput
                      : type.name === "By Ferry"
                      ? transferData.ferryInput
                      : type.name === "By Boat"
                      ? transferData.boatInput
                      : ""
                  }
                  onChange={(e) => {

                    switch (type.name) {
                      case "By Car":
                        dispatch(setcarInput(e.target.value));
                        break;
                      case "By Suv":
                        dispatch(setsuvInput(e.target.value));
                        break;
                      case "By Van":
                        dispatch(setvanInput(e.target.value));
                        break;
                      case "By Ferry":
                        dispatch(setferryInput(e.target.value));
                        break;
                      case "By Boat":
                        dispatch(setBoatInput(e.target.value));
                        break;
                      default:
                        break;
                    }
                  }}
                  className={
                    type.name === "By Ferry" || type.name === "By Boat"
                      ? CSS.FerryBoatInput
                      : CSS.TransInput
                  }
                />
                <br/>
              </div>
            ))}
            </div>
          </div>
        </div>
      </div>
      <div className={CSS.saveButton}>
        <button
          className={CSS.saveButtonText}
          onClick={handleSubmit}
          disabled={loading}
        >
          {"Save"}
        </button>
      </div>
      {error && <p>Error: {error}</p>}
      <div className={CSS.backButton}>
        <button
          className={CSS.backButtonText}
          onClick={() => navigate(-1)}
          disabled={loading}
        >
          {"Back"}
        </button>
      </div>
    </div>
  );
};

export default CreateTransfer;
