import { objectToQueryString } from "../../client/utils";
import { request } from "../request";

export const createQuotation = async (data) => {
    const response = await request("post","quotations", data);
    return response;
};


export const getQuotationsApi = async (params) => {
    let url = "quotations?";
    if (params) url += params;
    const response = await request("get", url);
    return response.data;
}

export const getQuotation = async (id) => {
    const response = await request("get",`quotations/${id}`);
    return response;
}

export const deleteQuotationApi = async (id) => {
    const response = await request("delete",`quotations/${id}`);
    return response;
}

export const updateQuotationApi = async (id, data) => {
    const response = await request("put",`quotations/${id}`, data);
    return response;
}