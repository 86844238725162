import React, { useState, useEffect, useRef } from 'react';
import CSS from './HotelDetails.module.css';
import image_icon from '../../../../assets/icons/hotel-dummy.svg';
import RoomsCard from '../../../shared/RoomsCard/RoomsCard';
import ProgressLoader from '../../../shared/ProgressLoader/ProgressLoader';
import { useParams } from 'react-router-dom';
import { getHotelAction, updateCityAction, updateHotelNameAction, updateContractExpiryAction, updateContractStartAction } from '../../../../services/client/hotel/index';
import { useSelector } from 'react-redux';
import { selectHotel, selectLoading, selectRooms } from '../../../../services/features/hotels/hotelSlice';
import { Cities } from '../../../../services/client/utils/index';
import { updateHotel } from '../../../../services/client/hotel/update-hotel';
import { useNavigate } from 'react-router-dom';


// Function to format date for input
function formatDateForInput(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}


const HotelDetails = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false); // State to manage image loading
  const [imageFile, setImageFile] = useState(null); // State to hold the uploaded image
  const loading = useSelector(selectLoading);
  const hotelDetails = useSelector(selectHotel);
  const [city, setCity] = useState(hotelDetails.city);
  const [attachments, setAttachments] = useState([]);
  const hotelID = useParams();
  const dropdownRef = useRef(null);
  const fileInputRef = useRef(null); // Reference to the file input element

  const rooms = useSelector(selectRooms);

  const navigate = useNavigate();

  //get hotel details.
  useEffect(() => {
    const fetchHotelDetails = async () => {
      getHotelAction(hotelID.id);
    };
    fetchHotelDetails();
  }, [hotelID.id]);

  //updating the hotel.
  const updateHotelDetails = () => {
    
    const details = { ...hotelDetails, rooms: rooms };
    updateHotel(attachments, details, navigate);
  };

  const handleHotelNameChange = (e) => {
    updateHotelNameAction(e.target.value);
  };

  const handleCityNameChange = (e) => {
    updateCityAction(e.target.value);
  };

  const handleContractStart = (e) => {
    updateContractStartAction(e.target.value);
  };

  const handleContractExpiry = (e) => {
    updateContractExpiryAction(e.target.value);
  };


  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const selectCity = (selectedCity) => {
    updateCityAction(selectedCity);
    setCity(selectedCity);
    toggleDropdown();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAttachments(file);
    } else {
      setAttachments([]);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      {loading ? (
        <ProgressLoader size={'lg'} />
      ) : (
        <div className={CSS.containerPage}>
          <div className={CSS.containerBackground} style={{ backgroundImage: `url(${hotelDetails.images?.[0]?.file})` }}>
            <div className={CSS.innerContainer}>{hotelDetails.name}</div>
          </div>
        
          <div className={CSS.hotelAndCityContainer}>
            <div className={CSS.cityContainer}>
              <div className={CSS.cityTitle}>City</div>
              <div ref={dropdownRef}>
                <input
                  type="text"
                  className={CSS.searchBarCity}
                  onChange={handleCityNameChange}
                  onClick={toggleDropdown}
                  value={hotelDetails.city}
                />
                {dropdownVisible && (
                  <div className={CSS.dropdown}>
                    {Cities.map((city) => (
                      <div key={city} className={CSS.dropdownItem} onClick={() => selectCity(city)}>{city}</div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className={CSS.hotelContainer}>
              <div className={CSS.cityTitle}>Hotel Name</div>
              <input type="text" value={hotelDetails.name} className={CSS.searchBarCity} onChange={handleHotelNameChange} />
            </div>
          </div>
          <div className={CSS.alignContainers}>
          <div className={CSS.imagesContainer}>
            <span className={CSS.hotelTitle}>Hotel Image</span>
            <div className={CSS.hotelImageContainer} onClick={() => fileInputRef.current.click()}>
              {loadingImage ? (
                <ProgressLoader size={'sm'} />
              ) : (
                <div className={CSS.image}>
                  <img src={imageFile ? URL.createObjectURL(imageFile) : image_icon} alt="Hotel icon" />
                </div>
              )}
              <input type="file" accept="image/*" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileUpload} />
            </div>   
          </div>    
              <div className={CSS.constractContainer}>
                <div className={CSS.hotelTitle}>Contract</div>
                  <div className={CSS.innerContainers}>
                    <div className={CSS.startContainer}>
                      <div className={CSS.contractTitle}>Contract Start</div>
                      <input 
                        type="date" 
                        className={CSS.constractInput}  
                        value={formatDateForInput(hotelDetails.contract_start)} 
                        onChange={handleContractStart} 
                      />
                    </div>
                    <div className={CSS.endContainer}>
                      <div className={CSS.contractTitle}>Contract Expiry</div>
                      <input 
                        type="date" 
                        className={CSS.constractInput} 
                        value={formatDateForInput(hotelDetails.contract_expiry)} 
                        onChange={handleContractExpiry}
                      />
                    </div>
                  </div>
              </div>
            </div>
          <span className={CSS.roomTitle}>Rooms</span>
          <div className={CSS.roomCardContainer}>
            <div className="add-rooms-card">
              <RoomsCard />
            </div>
          </div>
          <div className={CSS.saveButton}>
            <button className={CSS.saveButtonText} onClick={updateHotelDetails}>Save</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HotelDetails;
