import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSightSeeingsApi, getSightSeeing } from "../../api/sightseeings/index";
import { deleteSightSeeingApi } from "../../api/sightseeings/index";

// Fetch sightseeings
export const fetchSightseeings = createAsyncThunk(
  "sightseeings/fetchSightSeeings",
  async () => {
    const response = await getSightSeeingsApi();
    return response.data;
  }
);

// Fetch sightseeing by specific id.
export const fetchSightseeing = createAsyncThunk(
  "sightseeings/fetchSightseeing",
  async (id) => {
    const response = await getSightSeeing(id);
    return response.data;
  }
);

//delete sightseeing API.
export const deleteSightSeeing = createAsyncThunk(
  "sightseeings/deleteSightSeeing",
  async (id) => {
    const response = await deleteSightSeeingApi(id);
    return response.data;
  }
);

const SightSeeingsSlice = createSlice({
  name: "sightseeings",
  initialState: {
    sightseeing: {
      code: "",
      city: "",
      name: "",
      images: [],
      inclusions: "",
      ticket_only: 0,
      price_sic: 0,
      by_car: 0,
      by_suv: 0,
      by_van: 0,
      by_boat: 0,
      by_ferry: 0, 
    },
    
    sightseeings: [],
  },

  // Reducers
  reducers: {

    setSightSeeing: (state, action) => {
      state.sightseeing = {...state.sightseeing , ...action.payload};
    }

  },

  extraReducers: (builder) => {
    builder
    
    .addCase(fetchSightseeings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.sightseeings = action.payload.data;
    })

    .addCase(fetchSightseeing.fulfilled, (state, action) => {
      
      state.isLoading = false;
      state.sightseeing.city = action.payload.city;
      state.sightseeing.name = action.payload.name;
      state.sightseeing.code = action.payload.code;
      state.sightseeing.inclusions = action.payload.inclusions;
      state.sightseeing.images = action.payload.images;
      
      const tickets = action.payload.types.find((type) => type.type === 0);

      (!tickets) ? state.sightseeing.ticket_only = 0 : state.sightseeing.ticket_only = tickets.cost;

      const sic = action.payload.types.find((type) => type.type === 1);
      (!sic) ? state.sightseeing.price_sic = 0 : state.sightseeing.price_sic = sic.cost;

      const car = action.payload.types.find((type) => type.type === 2);
      (!car) ? state.sightseeing.by_car = 0 : state.sightseeing.by_car = car.cost;

      const suv = action.payload.types.find((type) => type.type === 3);
      (!suv) ? state.sightseeing.by_suv = 0 : state.sightseeing.by_suv = suv.cost;

      const van = action.payload.types.find((type) => type.type === 4);
      (!van) ? state.sightseeing.by_van = 0 : state.sightseeing.by_van = van.cost;

      const boat = action.payload.types.find((type) => type.type === 5);
      (!boat) ? state.sightseeing.by_boat = 0 : state.sightseeing.by_boat = boat.cost;

      const ferry = action.payload.types.find((type) => type.type === 6);
      (!ferry) ? state.sightseeing.by_ferry = 0 : state.sightseeing.by_ferry = ferry.cost;

    })
  
    .addCase(deleteSightSeeing.fulfilled, (state, action) => {
      state.sightseeings = state.sightseeings.filter((sight) => sight.id !== action.payload.id);
    })
  },
});

export const { setSightSeeing } = SightSeeingsSlice.actions;
export const selectSightseeings = (state) => state.sightseeings.sightseeings;
export const selectSightseeing = (state) => state.sightseeings.sightseeing;
export const selectSightseeingsLoading = (state) =>state.sightseeings.status === 'loading';
export default SightSeeingsSlice.reducer;

