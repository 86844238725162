import { store } from '../../../features/store'
import { errorNotification, successNotification } from '../../notification'
import { addRoom, updateRoom, setRooms, removeSelectedRoom } from '../../../features/hotels/hotelSlice'


const createHotelAPI = require('../../../api/hotel/create-hotel/index')
const attachmentApi = require('../../../client/attachments/index')

//create hotel function.
export const createHotel = async (file, data, navigate) =>{
    
    if(file)
    {
        const uploadFile = await attachmentApi.uploadImage(file)

        data.images = [uploadFile.data]
    }
    
    const response = await createHotelAPI.createHotelApi(data);

    //error handling.
    if(response.status)
    {
        successNotification(response.message);
        navigate('/hotels')
    }
    else{
        errorNotification(response.message)
    }

    return response.data;
}

//add room action.
export const addRoomAction = (data) => {

    store.dispatch(addRoom(data));
    
}

//update rooms action.
export const updateRoomsAction = (data) => {
    
        store.dispatch(updateRoom(data));
}

//setRooms action
export const setRoomsAction = (data) => {
    store.dispatch(setRooms(data));
}

//remove selected room action.
export const removeSelectedRoomAction = () => {
    store.dispatch(removeSelectedRoom());
}