import React, { useEffect } from 'react'
import CSS from './AgentDetails.module.css'
import { setAgentAction, fetchAgentAction } from '../../../../services/client/agent'
import { useSelector } from 'react-redux'
import { selectAgent } from '../../../../services/features/agent/agentSlice'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { updateAgentAction } from '../../../../services/client/agent'
import Select from 'react-select'
import { Countries } from '../../../../services/client/utils/index'

const AgentDetails = () => {

    //selector for the redux state.
    const agentData = useSelector(selectAgent);
    const navigate = useNavigate();    

    const agentID = useParams();
    useEffect(() => {
        //fetching the agent data.
        fetchAgentAction(agentID.id);
    }, [])

    //handling the onchange functions.
    const handleNameChange = (e) => {
        setAgentAction({company: e.target.value})
    } 

    const handleCountryName = (e) => {
        setAgentAction({country: e.target.value})
    }

    const handlePhoneNumber = (e) => {
        setAgentAction({phone: e.target.value})
    }

    const handdleAddress = (e) => {
        setAgentAction({address: e.target.value})
    }

    const handleBackButton = () => {
        
        //set the agent data to empty.
        setAgentAction({
            name: '',
            phone: '',
            address: '',
            country: '',
            created_at: ''
        })
    }

    const handleCountryChange = (selectedOption) => {
        if (selectedOption) {
            setAgentAction({ country: selectedOption.label });
        } else {
            setAgentAction({ country: 'Select country' });
        }
    };

    const handleOnSubmit = () => {

        //data passing to the api.
        const data = {

            company: agentData.company,
            phone: agentData.phone,
            country: agentData.country,
            address: agentData.address
        }   

        //updating the agent action.
        updateAgentAction(agentData.id,data, navigate);

    }

    return (

    <div className={CSS.AddAgentContainer}>
        {/* Input for name and date */}
        <div className={CSS.InnerAgentContainer}>
            <div className={CSS.containerForName}>
                <label className={CSS.labelForName}>Agent Name</label>
                <input className={CSS.inputForName} type="text" value={agentData.company} onChange={handleNameChange}/>
            </div>
            <div className={`${CSS.containerForName} ${CSS.selectContainer}`}>
                    <label className={CSS.labelForName}>Country</label>
                    <Select
                        className={CSS.dropdownContainer}
                        options={Countries}
                        onChange={handleCountryChange}
                        value={agentData.country}
                    />
                </div>
        </div>

        {/* Input for the phone number and address */}
        <div className={CSS.InnerAgentContainerForLowerRow}>
            <div className={CSS.containerForName}>
                <label className={CSS.labelForName}>Phone Number</label>
                <input className={CSS.inputForName} type="text" value={agentData.phone} onChange={handlePhoneNumber}/>
            </div>
        </div>

        {/* Input for the address and the button     */}
        <div className={CSS.InnerAgentContainerForLowerRow}>
            <div className={CSS.containerForName}>
                <label className={CSS.labelForName}>Address</label>
                <textarea className={CSS.textAreaForAddress} type="date" value={agentData.address} onChange={handdleAddress}/>
            </div>
            <div className={CSS.containerForName}>
                <Link to={"/agents"} className={CSS.linkButton} onClick={handleBackButton}><button className={CSS.AddAgentButton}>Back</button></Link>
                <button className={CSS.AddAgentButton1} onClick={handleOnSubmit}>Update Agent</button>
            </div>
            
        </div>
    </div>
  )
}

export default AgentDetails
