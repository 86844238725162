import { fetchSightseeings, fetchSightseeing } from '../../features/sightseeings/SightSeeingsSlice';
import { store } from '../../features/store'
import { setSightSeeing } from '../../features/sightseeings/SightSeeingsSlice';
import { errorNotification, successNotification } from '../../client/notification/index';
import { updateSightseeingApi } from '../../api/sightseeings/update-sightseeing/index';
import { deleteSightSeeing } from '../../features/sightseeings/SightSeeingsSlice';

const attachmentApi = require('../../client/attachments/index')
const createSightseeingApi = require('../../api/sightseeings/create-sightseeing/index')

//create sightseeing function.
export const createSightseeing = async (file, data, navigate) =>{

    if(file)
    {
        const uploadFile = await attachmentApi.uploadImage(file);
        data.images = [uploadFile.data];
    }

    const response = await createSightseeingApi.createSightseeingApi(data);

    //error handling.
    if(response.status)
    {
        successNotification(response.message);
        navigate('/sightseeings');
    }
    else{
        errorNotification(response.message);
    }

    return response.data;
}

export const getSightSeeingsAction = () =>{
    store.dispatch(fetchSightseeings());
}

//actions for reducers in the sightseeings.
export const setSightSeeingAction = (data) =>{
    store.dispatch(setSightSeeing(data));
}

export const getSightSeeingByIDAction = (id) =>{
    setSightSeeingAction({code: '', city: '', name: '', inclusions: '', ticket_only: 0, price_sic: 0, by_car: 0, by_suv: 0, by_van: 0, by_boat: 0, by_ferry: 0, images: []});
    store.dispatch(fetchSightseeing(id));
}

//updating the sight seeing.
export const updateSightSeeingAPI = async (file, data, navigate) =>{

    if(file)
    {
        const uploadFile = await attachmentApi.uploadImage(file)

        const updatData = {
            id: null,
            file: uploadFile.data
        }

        const images = [updatData];

        data = {...data, images:images}
    }

    const response = await updateSightseeingApi(data.id, data);

    //error handling.
    if(response.status)
    {
        successNotification(response.message);
        navigate('/sightseeings');
    }
    else{
        errorNotification(response.message)
    }
    
}


//delete sight seeing.
export const deleteSightSeeingAction = (id) =>{

    store.dispatch(deleteSightSeeing(id));
}