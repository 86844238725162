import React from 'react';
import Nav from 'react-bootstrap/Nav';
import SearchIcon from '../../../assets/icons/search.svg';
import NotificationIcon from '../../../assets/icons/notification.svg';
import './AccountNavbar.css';


function AccountNavbar({ heading }) {
  return (
    <Nav className='navbar'>
      <span className='heading'>{heading}</span>
      <img src={NotificationIcon} alt='notification' className='notifications' />
    </Nav>
  );
}

export default AccountNavbar;
