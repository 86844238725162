const localStorageService = require('../local-storage')
const authAPI = require('../../api/auth/index')

export const loginUser  = async (username, password,  navigate)  =>  {
    const res = await authAPI.loginUserAPI({username, password});
    
    if (res.data && res.data.access_token) {
        localStorageService.setAccessToken(res.data.access_token);
        navigate('/overview')
        return true;
    }  
    return false;
}
export const isLoggedIn = () => {
    if (! localStorageService.getAccessToken() ) return false;
    return true;
}

export const Logout = () => {
    localStorageService.removeAccessToken();
    window.location.href = '/login';
}