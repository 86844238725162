import React, { useState } from "react";
import { loginUser } from "../../../services/client/auth";
import { useNavigate } from "react-router-dom";

import css from "./login.module.css";
import "bootstrap/dist/css/bootstrap.min.css";

import RectangleIcon from "../../../assets/icons/Rectangle.png";
import ProfileIcon from "../../../assets/icons/profile.png";

export const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);

  const login = async () => {
    setLoading(true);
    try {
      await loginUser(email, password, navigate);
      setApiError(false); // Reset error state if API call succeeds
    } catch (error) {
      setApiError(true); // Set error state if API call fails
      console.error("Login failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={css.Wrapper}>
      <div className={css.BackgroundColor}>
        <div className="d-flex flex-column align-items-center">
          <div className={css.LogoLogin}>
            <img src={RectangleIcon} alt="" />
          </div>
          <div className={css.Header}>
            <div className={css.Text}>USER LOGIN</div>
          </div>
          <div className={css.Inputs}>
            <div className={css.Input}>
              <img className={css.ProfileImg} src={ProfileIcon} alt="" />
              <input type="text" className={css.UsernameInput} placeholder="Your Username" onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className={css.Password}>
              <input type="password" className={css.PasswordInput} placeholder="* * * * * *" onChange={(e) => setPassword(e.target.value)} />
            </div>
          </div>

          <button type="button" className={css.BtnSignin} disabled={loading || apiError || email === '' || password === ''} onClick={login}>
            {loading ? 'Signing In...' : 'Sign In'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
