const attachmentApi = require('../../api/attachments/index')

export const uploadImage = async (file) =>{

    const body = new FormData();
    body.append('media', file);
    
    const response = await attachmentApi.uploadImageApi(body);

    return response;
}