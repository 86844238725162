import React, { useEffect } from 'react'
import SearchIcon from '../../../assets/icons/search.png'
import CSS from './Agents.module.css'
import { useSelector } from 'react-redux'
import { getAgentsAction, deleteAgentAction } from '../../../services/client/agent/index'
import { selectAgents } from '../../../services/features/agent/agentSlice'
import { Link, useNavigate } from 'react-router-dom'
import deleteIcon from '../../../assets/icons/delete.svg'
import editIcon from '../../../assets/icons/edit.svg'

const Agents = () => {

    const agentsData = useSelector(selectAgents);

    //calling the api to get the agents.
    useEffect(() => {
      getAgentsAction();
    }, [])


    //function to delete the agent.
    const handleDelete = (id) => {

      //call the delete agent api.
      deleteAgentAction(id);

    }

    const formatDate = (dateString) => {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };
    
    return (
    <div className={CSS.agentContainer}>
      <div className={CSS.addAgentButtonContainer}><Link to={"/agent/addagent"}><button className={CSS.addAgentButton}>Add Agent</button></Link></div>
        <div className={CSS.searchBarsContainer}>
          <img src = {SearchIcon} alt="search" className={CSS.searchIcon}></img>
          <input type="text" placeholder="Agent Name" className={CSS.searchBar}/>
          <img src = {SearchIcon} alt="search" className={CSS.searchIcon1}></img>
          <input type="text" placeholder="Date" className={CSS.searchBar}/>
          <img src = {SearchIcon} alt="search" className={CSS.searchIcon2}></img>
          <input type="text" placeholder="Search" className={CSS.searchBar}/>
        </div>

        <div className={CSS.tableContainer}>

        <table className={`${CSS["TableContainer"]} ${CSS["TableCustom"]}`}>
          <thead>
            <tr className={CSS.TableHead}>
              <th className={CSS.SerialNumber}>ID</th>
              <th className={CSS.TypeInput}>Agent Name</th>
              <th className={CSS.TypeInput}>Country</th>
              <th className={CSS.TypeInput}>Phone Number</th>
              <th className={CSS.TypeInput}>Dates</th>
              <th className={CSS.CustomThead}>Address</th>
              <th className={CSS.actionsColumn}>Actions</th>
            </tr>
          </thead>
          <tbody>

            {agentsData.length > 0 ? (

              agentsData.map((agent, index) => (
                
                (index % 2) === 0 ?
                <tr key={index} className={CSS.innerData}>
                  <td className={CSS.SerialNumber1}> <Link to={`/agents/details/${agent.id}`} className={CSS.SerialNumber1}>{agent.id}</Link></td>
                  <td className={CSS.TypeInput}>{agent.company}</td>
                  <td className={CSS.TypeInput}>{agent.country}</td>
                  <td className={CSS.TypeInput}>{agent.phone}</td>
                  <td className={CSS.DateInput}>{formatDate(agent.created_at)}</td>
                  <td className={CSS.CustomThead}>{agent.address}</td>


                  <td className={CSS.actionsColumn}>
                    <div className={CSS.actionsContainer}>
                      
                      {/* delete icon */}
                      <img src={deleteIcon} alt="delete" className={CSS.iconContainer} onClick={() => handleDelete(agent.id)}></img>

                      {/* edit icon */}
                      <Link to= {`/agents/details/${agent.id}`}>
                        <img src={editIcon} alt="delete" className={CSS.iconContainer}></img>
                      </Link>
                    </div>
                  </td>
                </tr>
                :
                <tr key={index} className={CSS.innerData1}>
                  
                  <td className={CSS.SerialNumber1}> <Link to={`/agents/details/${agent.id}`} className={CSS.SerialNumber1}>{agent.id}</Link></td>
                  <td className={CSS.TypeInput}>{agent.company}</td>
                  <td className={CSS.TypeInput}>{agent.country}</td>
                  <td className={CSS.TypeInput}>{agent.phone}</td>
                  <td className={CSS.DateInput}>{formatDate(agent.created_at)}</td>
                  <td className={CSS.CustomThead}>{agent.address}</td>
                  <td className={CSS.actionsColumn}>
                    <div className={CSS.actionsContainer}>
                      
                      {/* delete icon */}
                      <img src={deleteIcon} alt="delete" className={CSS.iconContainer} onClick={() => handleDelete(agent.id)}></img>

                      {/* edit icon */}
                      <Link to= {`/agents/details/${agent.id}`}>
                        <img src={editIcon} alt="delete" className={CSS.iconContainer}></img>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" className={CSS.noAgetnsAvailable}><center>No agents available</center></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>   
    </div>
  )
}

export default Agents
