import { errorNotification, successNotification } from '../../notification'
import {updateHotelApi} from '../../../api/hotel/update-hotel/index'


//importing the attachment api.
const attachmentApi  = require('../../../client/attachments/index')

export const updateHotel = async (file, data, navigate) => {

     
    if(file)
    {
        const uploadFile = await attachmentApi.uploadImage(file)

        const updatData = {
            id: null,
            file: uploadFile.data
        }

        const images = [updatData];

        data = {...data, images:images}
    }
    
    const response = await updateHotelApi(data.id, data);

    //error handling.
    if(response.status)
    {
        successNotification(response.message);
        navigate('/hotels')
    }
    else{
        errorNotification(response.message)
    }
    
}

