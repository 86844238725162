import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import CSS from "./TransferListing.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {selectTransfers,fetchTransfers,selectLastPage,selectCurrentPage} from "../../../services/features/transfers/transferSlice"
import ProgressLoader from  "../../shared/ProgressLoader/ProgressLoader";
import TransferDetails from "../TransferListing/TransferDetails";



const TransferListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const transferList = useSelector(selectTransfers);
  const currentPage = useSelector(selectCurrentPage);
  const lastPage = useSelector(selectLastPage);


  useEffect(() => {
    dispatch(fetchTransfers());
  }, []);


  const handleCreateTransfer = () => {
    navigate("/create/transfer");
  };
  const handleUpdateTransfer = () => {
    <TransferDetails/>
  }
  const loadMoreTransfers = () => {
    // Fetch the next page of transfers
    if (currentPage < lastPage) {
      dispatch(fetchTransfers(currentPage + 1));
    }
  };

  return (
    <div className="position-relative">
      <button
        type="button"
        className={CSS.BtnCreateTable}
        onClick={() => handleCreateTransfer()}
      >
        Create Transfer
      </button>
      <div className={`${CSS["CustomTableContainer"]}`}>
        <table
          className={`${CSS["TableContainer"]} ${CSS["TableCustom"]}`}
        >
          <thead>
            <tr className={CSS.TableHead}>
              <th className={CSS.SerialNumber}>Sr</th>
              <th className={CSS.CustomThead}>From</th>
              <th className={CSS.CustomThead}>To</th>
              <th className={CSS.TypeInput}>By Car</th>
              <th className={CSS.TypeInput}>By Suv</th>
              <th className={CSS.TypeInput}>By Van</th>
              <th className={CSS.TypeInput}>By Ferry</th>
              <th className={CSS.TypeInput}>By Boat</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(transferList.transfers) && transferList.transfers.length > 0 ? (
              transferList.transfers.map((transfer, index) => (
                <tr key={index}>
                  
                  <td className={CSS.SerialNumber}><Link to={"/transfers/details/" +transfer.id}>{transfer.id}</ Link> </td>
                  <td className={CSS.CustomTd}>{transfer.from}</td>
                  <td className={CSS.CustomTd}>{transfer.to}</td>
                  <td className={CSS.TypeInput}>{transfer.types.carInput}</td>
                  <td className={CSS.TypeInput}>{transfer.types.suvInput}</td>
                  <td className={CSS.TypeInput}>{transfer.types.vanInput}</td>
                  <td className={CSS.TypeInput}>{transfer.types.ferryInput}</td>
                  <td className={CSS.TypeInput}>{transfer.types.boatInput}</td>
                  </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className={CSS.noTransfersAvailable}><center>No transfers available</center></td>
              </tr>
            )}
          </tbody>
        </table>


         {/* {currentPage < lastPage && (
          <button onClick={loadMoreTransfers}>Load More</button>
        )}  */}

        {transferList?.length === 0 && (
          <div>
            <ProgressLoader size={"lg"} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TransferListing;
