import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  postbookingrequestApi,
  getBookingRequestAPi,
  getbookingrequestsAPi,
  deleteBookingRequestApi
} from "../../api/bookingrequest/index";

export const fetchBookingRequests = createAsyncThunk(
  "bookingrequests/fetchBookingrequests",
  async () => {
    const response = await getbookingrequestsAPi();
    return response.data; 
  }
);
export const fetchBookingrequest = createAsyncThunk(
  "bookingrequests/fetchBookingrequest",
  async (id) => {
    const response = await getBookingRequestAPi(id);
    return response.data; 
  }
);

export const fetchBookingrequests = createAsyncThunk(
  "bookingrequests/fetchBookingrequests",
  async () => {
    return await postbookingrequestApi();
    
  }
);

export const deleteBookingRequest = createAsyncThunk(
  "bookingrequests/deleteBookingRequest",
  async (id) => {
    return await deleteBookingRequestApi(id);
  }
)

const bookingRequestsSlice = createSlice({
  name: "bookingrequests",
  initialState:{
    bookingrequest: {
      agent_name: "",
      agent_id: null,
      customer_name: "",
      customer_phone: "",
      no_of_pax: "",
      travel_date: "",
    },
    bookingrequests:[]
  },
  reducers: {
    setAgentName: (state, action) => {
      state.bookingrequest.agent_name = action.payload;
    },
    setAgentId: (state, action) => {
      state.bookingrequest.agent_id = action.payload;
    },
    setCustomerName: (state, action) => {
      state.bookingrequest.customer_name = action.payload;
    },
    setCustomerPhone: (state, action) => {
      state.bookingrequest.customer_phone = action.payload;
    },
    setTravelDate: (state, action) => {
      state.bookingrequest.travel_date = action.payload;
    },
    setNoOfPax: (state, action) => {
      state.bookingrequest.no_of_pax = action.payload;
    },
    resetState: (state) => {
      state.bookingrequest.agent_id=null;
      state.bookingrequest.customer_name ="";
      state.bookingrequest.customer_phone ="";
      state.bookingrequest.no_of_pax ="";
      state.bookingrequest.travel_date ="";
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchBookingRequests.fulfilled, (state, action) => {
      state.bookingrequests = action.payload.data;
    });

    builder.addCase(fetchBookingrequest.fulfilled, (state, action) => {
        state.bookingrequest = {...state.bookingrequest , ...action.payload};
    });
    builder.addCase(deleteBookingRequest.fulfilled,(state, action) =>{
      state.bookingrequest = state.bookingrequests.filter((item)=> item.id !== action.payload.id);
    })
  },
});

export const {
  setAgentName,
  setAgentId,
  setCustomerName,
  setCustomerPhone,
  setNoOfPax,
  setTravelDate,
  resetState,
  updateBookingRequestDetails,
} = bookingRequestsSlice.actions;

export const selectBookingRequest = (state) =>state.bookingrequests.bookingrequest;
export const selectBookingRequests = (state) => state.bookingrequests.bookingrequests;
export default bookingRequestsSlice.reducer;
