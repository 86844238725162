import {request} from '../request'

export const getAgentsApi = async () => {
    return await request("get", "agents");
  };

  export const getAgentAPI = async (id) => {
    return await request("get",`agents/${id}`)
  }
 

  export const addAgentApi = async (data) => {
    return await request("post","agents",data)
}

export const updateAgentApi = async (id,data) => {
    return await request("put",`agents/${id}`,data)
}

//delete agent api.
export const deleteAgentApi = async (id) => {
    return await request("delete",`agents/${id}`)
}