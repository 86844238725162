import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import CSS from "./Request.module.css";
import { useNavigate } from "react-router-dom";
import deleteIcon from "../../../assets/icons/delete.svg";
import editIcon from "../../../assets/icons/edit.svg";
import { selectBookingRequests } from "../../../services/features/bookingrequest/bookingrequestSlice";
import { getBookingrequestsAction } from "../../../services/client/bookingrequest/index";
import { deleteAction } from "../../../services/client/bookingrequest/index";

const Request = () => {
  const navigate = useNavigate();
  const bookingrequestData = useSelector(selectBookingRequests);

  useEffect(() => {
    getBookingrequestsAction();
  }, []);

  const handleCreateRequest = () => {
    navigate("/request/createrequest");
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleRequest = () => {
    navigate("/request");
  };

  const handleDelete = (id) => {
    deleteAction(id);
  };

  return (
    <div className="position-relative">
      <button
        type="button"
        className={CSS.BtnCreateTable}
        onClick={handleCreateRequest}
      >
        Create Request
      </button>
      <div className={`${CSS["CustomTableContainer"]}`}>
        <table className={`${CSS["TableContainer"]} ${CSS["TableCustom"]} table table-striped`}>
          <thead>
            <tr className={CSS.TableHead}>
              <th className={CSS.AgentName}>Request Id</th>
              <th className={CSS.AgentName}>Agent Name</th>
              <th className={CSS.Guestname}>Guest Name</th>
              <th className={CSS.Guestnumber}>Guest Phone</th>
              <th className={CSS.Nopax}>No# Pax</th>
              <th className={CSS.Traveldate}>Travel Date</th>
              <th className={CSS.AgentName}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {bookingrequestData.length > 0 ? (
              bookingrequestData.map((bookingRequest, index) => (
                <tr key={index}>
                  <td className={CSS.AgentId}>
                    {bookingRequest.id}
                    </td>
                  <td className={CSS.AgentName}>
                    {bookingRequest.agent?.company}
                  </td>
                  <td className={CSS.Guestname}>
                    {bookingRequest.customer_name}
                  </td>
                  <td className={CSS.Guestnumber}>
                    {bookingRequest.customer_phone}
                  </td>
                  <td className={CSS.CustomNopax}>
                    {bookingRequest.no_of_pax}
                  </td>
                  <td className={CSS.Traveldate}>
                    {formatDate(bookingRequest.travel_date)}
                  </td>
                  <td className={CSS.actionsColumn}>
                    <div className={CSS.actionsContainer}>
                      {/* delete icon */}
                      <img
                        src={deleteIcon}
                        alt="delete"
                        className={CSS.iconContainer}
                        onClick={() => handleDelete(bookingRequest.id)}
                      ></img>
                      {/* edit icon */}
                      <Link to={`/request/details/${bookingRequest.id}`}>
                        <img
                          src={editIcon}
                          alt="delete"
                          className={CSS.iconContainer}
                        ></img>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" className={CSS.noRequestsAvailable}><center>No agents available</center></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Request;
