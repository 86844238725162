import React from 'react';
import CSS from './AddAgent.module.css';
import { setAgentAction } from '../../../../services/client/agent';
import { useSelector } from 'react-redux';
import { selectAgent } from '../../../../services/features/agent/agentSlice';
import { addAgentAction } from '../../../../services/client/agent';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Countries } from '../../../../services/client/utils/index';

const AddAgent = () => {
    const agentData = useSelector(selectAgent);
    const navigate = useNavigate();

    const handleNameChange = (e) => {
        setAgentAction({ name: e.target.value });
    };

    const handleCountryChange = (selectedOption) => {
        if (selectedOption) {
            setAgentAction({ country: selectedOption.label });
        } else {
            setAgentAction({ country: '' });
        }
    };

    const handlePhoneNumberChange = (e) => {
        setAgentAction({ phone: e.target.value });
    };

    const handleAddressChange = (e) => {
        setAgentAction({ address: e.target.value });
    };

    const handleOnSubmit = () => {
        const data = {
            company: agentData.name,
            phone: agentData.phone,
            country: agentData.country,
            address: agentData.address,
        };
        addAgentAction(data, navigate);
    };

    const handleBackButton = () => {
        setAgentAction({
            name: '',
            phone: '',
            address: '',
            country: '',
            created_at: '',
        });
    };

     //custom styles for the select component
     const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: 30,
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: 5,
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: state.isSelected ? 0 : 0,
        }),
    };

    return (
        <div className={CSS.AddAgentContainer}>
            <div className={CSS.InnerAgentContainer}>
                <div className={CSS.containerForName}>
                    <label className={CSS.labelForName}>Agent Name</label>
                    <input className={CSS.inputForName} type="text" placeholder="Agent Name" onChange={handleNameChange} />
                </div>
                <div className={`${CSS.containerForName} ${CSS.selectContainer}`}>
                    <label className={CSS.labelForName}>Country</label>
                    <Select
                        className={CSS.dropdownContainer}
                        options={Countries}
                        onChange={handleCountryChange}
                        placeholder="Select Country"
                        styles={customStyles}
                    />
                </div>
            </div>
            <div className={CSS.InnerAgentContainerForLowerRow}>
                <div className={CSS.containerForName}>
                    <label className={CSS.labelForName}>Phone Number</label>
                    <input className={CSS.inputForName} type="text" placeholder="XXXX-XXXXXX-X" onChange={handlePhoneNumberChange} />
                </div>
            </div>
            <div className={CSS.InnerAgentContainerForLowerRow}>
                <div className={CSS.containerForName}>
                    <label className={CSS.labelForName}>Address</label>
                    <textarea className={CSS.textAreaForAddress} placeholder="Address" onChange={handleAddressChange} />
                </div>
                <div className={CSS.containerForName}>
                    <Link to="/agents" className={CSS.linkButton} onClick={handleBackButton}>
                        <button className={CSS.AddAgentButton}>Back</button>
                    </Link>
                    <button className={CSS.AddAgentButton1} onClick={handleOnSubmit}>
                        Add Agent
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddAgent;
