import Overview from "../../../assets/icons/overview.svg";
import Quotation from "../../../assets/icons/quotation.svg";
import Hotels from "../../../assets/icons/hotels.svg";
import Sightseeings from "../../../assets/icons/sightseeings.svg";
import Transfers from "../../../assets/icons/transfers.svg";
import ROE from "../../../assets/icons/roe.svg";
import Helpandinformation from "../../../assets/icons/help-and-information.svg";
import Logout from "../../../assets/icons/logout.svg";
import Agents from "../../../assets/icons/Agents.svg";
import Operations from "../../../assets/icons/Operations.svg";

export const items = [
  {
    link: "/overview",
    title: "Overview",
    icon: Overview,
  },
  {
    link: "/request",
    title: "Request",
    icon: Overview,
  },
  {
    link: "/quotation",
    title: "Quotation",
    icon: Quotation,
  },
  {
    link: "/hotels",
    title: "Hotels",
    icon: Hotels,
  },
  {
    link: "/sightseeings",
    title: "Sight Seeing's",
    icon: Sightseeings,
  },
  {
    link: "/transfers",
    title: "Transfers",
    icon: Transfers,
  },
  // {
  //   link: "/roe",
  //   title: "ROE",
  //   icon: ROE,
  // },
  {
    link: "/agents",
    title: "Agents",
    icon: Agents,
  },
  {
    link: "/operations",
    title: "Operations",
    icon: Operations,
  },
];

export const btm_icons = [
  {
    link: "/helpandinformation",
    title: "Help and Information",
    icon: Helpandinformation,
  },
  {
    link: "/login",
    title: "Logout",
    icon: Logout,
  },
];

//cities
export const Cities = [
  "Bangkok",
  "Pattaya",
  "Phuket",
  "Krabi",
  "Koh Samui",
  "Phi Phi",
  "Koh Phanghan",
];

//rooms types
export const RoomTypes = [
  {
    type: 0,
    value: "Standard Room",
  },
  {
    type: 1,
    value: "Superior Room",
  },
  {
    type: 2,
    value: "Deluxe Room",
  },
  {
    type: 3,
    value: "Deluxe Plus",
  },
  {
    type: 4,
    value: "Deluxe Twin",
  },
  {
    type: 5,
    value: "Superior Pool View",
  },
  {
    type: 6,
    value: "Deluxe Pool View",
  },
  {
    type: 7,
    value: "Deluxe Pool Access",
  },
  {
    type: 8,
    value: "Premium Deluxe",
  },
  {
    type: 9,
    value: "Grand Deluxe",
  },
  {
    type: 10,
    value: "Premier Room",
  },
  {
    type: 11,
    value: "Junior Suite",
  },
  {
    type: 12,
    value: "Deluxe Family",
  },
  {
    type: 13,
    value: "Family Suite",
  },
  {
    type: 14,
    value: "Previlege Room",
  },
  {
    type: 15,
    value: "Superior Suite",
  },
];


  /*case NEW_INQUIRY = 'new_inquiry';
    case QUOTATION_SENT = 'quotation_sent';
    case FINAL_PAYMENT_RECEIVED = 'final_payment_received';
    case COMPLETED = 'completed';*/ 

export const QuotationStatus = [
  {
    value: 'new_inquiry',
    label: 'New Inquiry',
  },
  {
    value: 'quotation_sent',
    label: 'Quotation Sent',
  },
  {
    value: 'final_payment_received',
    label: 'Final Payment Received',//will be updated in the operations.
  },
  {
    value: 'completed',
    label: 'Completed',
  },
];


export const FromPlace = [
  {
    type: 4,
    value: "Airport",
  },
  {
    type: 0,
    value: "Hotel",
  },
  {
    type: 2,
    value: "Sightseeings",
  },
];

//From Airport
export const Airport = [
  {
    type: 0,
    name: "Karachi Airport",
  },
  {
    type: 1,
    name: "Islamabad Airport",
  },
  {
    type: 2,
    name: "Lahore Airport",
  },
];


export const TravelType = [
  {
    type: 0,
    name: "By Car",
  },
  {
    type: 1,
    name: "By Suv",
  },
  {
    type: 2,
    name: "By Van",
  },
  {
    type:3,
    name:"By Ferry",
  },
  {
    type:4,
    name:"By Boat",
  },
];

export const defaultAPIParams = {
  per_page: 100,
  page: 1,
  order_by_field: "created_at",
  order_by: "desc",
};

export const objectToQueryString = (obj) => {
  return Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
};

//tour details data

//ids for the different items 
    //id Item
    //0  hotel
    //1  Room
    //2 Sight Seeing
    //3 Transfers
    //4 Airports


export const TourDetailsData = [
  
  { value: '0', label: 'Hotel' },
  { value: '2', label: 'Sight Seeing' },
  { value: '3', label: 'Transfers' }, 
];

//countries.
export const Countries = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Brazil", label: "Brazil" },
  { value: "Brunei", label: "Brunei" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czechia", label: "Czechia" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Eswatini", label: "Eswatini" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Greece", label: "Greece" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Kosovo", label: "Kosovo" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Laos", label: "Laos" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "North Korea", label: "North Korea" },
  { value: "North Macedonia", label: "North Macedonia" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestine", label: "Palestine" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Qatar", label: "Qatar" },
  { value: "Romania", label: "Romania" },
  { value: "Russia", label: "Russia" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  { value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  { value: "South Korea", label: "South Korea" },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syria", label: "Syria" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Timor-Leste", label: "Timor-Leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States", label: "United States" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Vatican City", label: "Vatican City" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" }
];
