import { store } from "../../features/store";
import { createQuotation } from "../../api/quotations";
import { successNotification, errorNotification } from "../notification";
import { addQuotationItem, 
         setParticulars, 
         setDate, 
         setPrice, 
         setHotelRooms, 
         setRequestID, 
         setCheckIn, 
         setCheckOut, 
         setNumberOfNights,
         deleteQuotationItem,
         setResoueceType,
         setCostPerPax,
         setResourceId,
         fetchQuotation,
         deleteQuotation,
         clearQuotationItems,
         fetchQuotations,
         setResourseValueId, 
         updateQuotation} from "../../features/quotationSlice/quotationSlice";

    
export const addQuotationItemAction = (data) => {

    const quotationItem = {
        quotation_item_id: null,
        resource_type: null, 
        resource_id: null,
        resource_id_value: null, 
        resource: {
            resource_id: null,
            resource_name: '',
            
        },
        date: '',
        cost_per_pax: null,
        total_price: null, 
        item_accommodation: {
            no_of_nights: null,
            check_in: '', 
            check_out: '',
        },
        rooms: [
            {
                room_id: null,
                no_of_rooms: 0,
            }
        ], 
    };

    store.dispatch(addQuotationItem(quotationItem));
}

export const setParticularsAction = (particulars) => {
    store.dispatch(setParticulars(particulars));
}

export const clearQuotationItemsAction = () => {
    store.dispatch(clearQuotationItems());
}

export const setDateAction = (date) => {
    store.dispatch(setDate(date));
}

export const setCostPerPaxAction = (cost) => {
    store.dispatch(setCostPerPax(cost));
}


export const setPriceAction = (price) => {

    store.dispatch(setPrice(price));
}

export const setRoomsAction = (index, rooms, no_of_pax) => { 

    //calculating the price of the rooms. 
    const Price = rooms.reduce((acc, room) => {
        return acc + (room.no_of_rooms * room.cost_per_night);
      }
      , 0);

      setPriceAction({index,Price});
      setCostPerPaxAction({index: index, cost: Price / no_of_pax})

    store.dispatch(setHotelRooms({index, rooms: rooms}));
}

export const setRequestIDAction = (requestID) => {
    store.dispatch(setRequestID(requestID));
}

export const setCheckInAction = (date) => {
    store.dispatch(setCheckIn(date));
}

export const setCheckOutAction = (date) => {
    store.dispatch(setCheckOut(date));
}

export const setNumberOfNightsAction = (nights) => {
    store.dispatch(setNumberOfNights(nights));
}

export const deleteQuotationItemAction = (index) => {
    store.dispatch(deleteQuotationItem(index));
}

export const setResoueceTypeAction = (resourceType) => {
    store.dispatch(setResoueceType(resourceType));
}

export const setResourceIdAction = (resourceId) => {
    store.dispatch(setResourceId(resourceId));
}

export const setResourseValueIdAction = (resourceTypeId) => {
    store.dispatch(setResourseValueId(resourceTypeId));
}

//Create quotation 
export const createQuotationAction = async (data, navigate) => {
    
        const response = await createQuotation(data);

        console.log(response);
        //error handling.
        if(response.status)
        {
            successNotification(response.message);
            navigate('/quotation')
        }
        else{
            errorNotification(response.message)
        }

        return response;
    
};


//fetch quotations action
export const fetchQuotationsAction = async (params) => {
    store.dispatch(fetchQuotations(params));
}

//fetch quotation action
export const fetchQuotationAction = async (id) => {
    const response = await store.dispatch(fetchQuotation(id));
    
    if(response.status)
        {
            successNotification(response.message);
        }
        else{
            errorNotification(response.message)
        }

        return response;
    
}

//delete quotation action
export const deleteQuotationAction = async (id) => {
    store.dispatch(deleteQuotation(id));
}

export const updateQuotationAction = async (id, data) => {
    store.dispatch(updateQuotation({id, data}));
}

