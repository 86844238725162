import React from "react";
import { Row, Col } from "react-bootstrap";
import Sidebar from "../components/shared/Sidebar/Sidebar";
import AccountNavbar from "../components/shared/AccountNavbar/AccountNavbar";
import { Outlet } from "react-router-dom";
const { items } = require('../services/client/utils');
const {btm_icons} = require('../services/client/utils')

const DefaultLayout = ({ title }) => (
  <>
    <Row>
      <Col md={2} lg={2}>
        <Sidebar
            items={items} 
            bottom_icons = {btm_icons} 
        />
      </Col>
      <Col md={10} lg={10}>
        <AccountNavbar heading={title} />
        <Outlet/>
      </Col>
    </Row>
  </>
);

export default DefaultLayout;
