import React, { useEffect, useState } from 'react';
import CSS from './HotelModal.module.css';
import { useSelector } from 'react-redux';
import { getHotelAction, updateRoomQuantityAction } from '../../../services/client/hotel';
import { RoomTypes } from '../../../services/client/utils';
import ProgressLoader from '../ProgressLoader/ProgressLoader';
import AddQtyIcon from '../../../assets/icons/Add Qty.svg';
import MinusQtyIcon from '../../../assets/icons/Less Qty.svg';
import { selectRooms } from '../../../services/features/hotels/hotelSlice';
import { setCostPerPaxAction, setRoomsAction } from '../../../services/client/quotations';
import { setPriceAction } from '../../../services/client/quotations';
import { setCheckInAction, setCheckOutAction, setNumberOfNightsAction } from '../../../services/client/quotations';
import { selectQuotationItems } from '../../../services/features/quotationSlice/quotationSlice';
import { selectBookingRequest } from '../../../services/features/bookingrequest/bookingrequestSlice';

const HotelModal = ({ onClose, hotelID, index }) => {
  const [loading, setLoading] = useState(true);
  const roomsData = useSelector(selectRooms);
  const quotationItems = useSelector(selectQuotationItems);
  const bookingRequestData = useSelector(selectBookingRequest);

  useEffect(() => {

    const fetchHotelData = async () => {
      await getHotelAction(hotelID, true); 
      setLoading(false); 
      document.body.style.overflowY = 'hidden';
      
    };

    fetchHotelData();

    return () => {
      document.body.style.overflowY = 'auto';
    }
  }, []); 

  const rooms = roomsData || []; 

  const roomType = (type) => {
    return RoomTypes.find(room => room.type === type).value;
  }
  
  //check in
  const handleCheckIn = (e) => {
    
    const checkIn = {
      index: index,
      date: e.target.value
    }
    
    setCheckInAction(checkIn);
  }

  //check out
  const handleCheckOut = (e) => {

    const checkOut = {
      index: index,
      date: e.target.value
    }

    setCheckOutAction(checkOut);
  }


  //function for calculating no_of_nights
  const calculalteNoOfNights = (checkIn, checkOut) => {
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);
    const diffTime = Math.abs(checkOutDate - checkInDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }


  const handleAddQuantity = (room) => {
    const quantity = room.no_of_rooms || 0;
    const roomObj = {
      id: room.id,
      no_of_rooms: quantity + 1
    }
    updateRoomQuantityAction(roomObj);
  }

  const handleMinusQuantity = (room) => {
    const quantity = room.no_of_rooms || 0;
    if(quantity > 0) {
      const roomObj = {
        id: room.id,
        no_of_rooms: quantity - 1
      }
      updateRoomQuantityAction(roomObj);
    }
  }

  const handleAdd = (onClose) => {

    const CheckIn = quotationItems[index].item_accommodation.check_in;
    const CheckOut = quotationItems[index].item_accommodation.check_out;
    const noOfNights = calculalteNoOfNights(CheckIn, CheckOut);

    const nights = {
      index: index,
      nights: noOfNights
    }

    setNumberOfNightsAction(nights);

    //multiply the quantities of the rooms with the price of their corresponding room room
    const selectedRooms = rooms
                          .filter((room) => (room.no_of_rooms && room.no_of_rooms > 0))
                          .map((room) => ({ room_id: room.id, no_of_rooms: room.no_of_rooms, cost_per_night: room.cost_per_night }))

    //setting the selected rooms.
    setRoomsAction(index, selectedRooms, bookingRequestData.no_of_pax);

  
    
    
    
    //closing the modal
    onClose();

  }

  return (
    <div className={CSS.overlay}>
      <div className={CSS.popOut}>
        <h2 className={CSS.headingClass}>Hotel Details</h2>

        <div className={CSS.datesContainer}>
            {/* check in */}
            <div className={CSS.checkIn}>
              <label className={CSS.labelClass}>Check In</label>
              <input type="date" className={CSS.dateInput} onChange={handleCheckIn}/>
            </div>
            {/* check out */}
            <div className={CSS.checkIn}>
              <label className={CSS.labelClass}>Check Out</label>
              <input type="date" className={CSS.dateInput} onChange={handleCheckOut}/>
            </div>
        </div>

        {loading ? (
          <ProgressLoader size={"lg"}/> // Show loader while data is being fetched
        ) : (
          <div>
            <div className={CSS.tableContainer}>
              <table className={`${CSS["TableContainer"]} ${CSS["TableCustom"]}`}>
                <thead>
                  <tr className={CSS.TableHead}>
                    <th className={CSS.SerialNumber}>Sr.No</th>
                    <th className={CSS.TypeInput}>Room Type</th>
                    <th className={CSS.SerialNumber}>Capacity</th>
                    <th className={CSS.priceValue}>Price</th>
                    <th className={CSS.priceValue}>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {rooms.length > 0 ? (
                    rooms.map((room, index) => (
                      <tr key={index} className={index % 2 === 0 ? CSS.innerData : CSS.innerData1}>
                        <td className={CSS.SerialNumber1}>{index + 1}</td> 
                        <td className={CSS.TypeInput}>{roomType(room.type)}</td>
                        <td className={CSS.SerialNumber}>{room.capacity}</td>
                        <td className={CSS.priceValue}>{room.cost_per_night}</td>
                        <td className={CSS.priceValue}>
                        
                          <div className={CSS.quantityContainer}>

                            <img src={MinusQtyIcon} alt="minus" className={CSS.qtyIcons} onClick={() => handleMinusQuantity(room)}/>
                            <input type="text" className={index % 2 === 0 ? CSS.qtyInput: CSS.qtyInputWhiteBg} value={room.no_of_rooms || 0 } readOnly/>
                            <img src={AddQtyIcon} alt="add" className={CSS.qtyIcons} onClick={() => handleAddQuantity(room)} />

                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className={CSS.noAgetnsAvailable}><center>No rooms available</center></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            

            <div className={CSS.buttonContainer}>
              <button className={CSS.modalButtons} onClick={() => { onClose() }}>Close</button>
              <button className={CSS.modalButtons} onClick={() => { handleAdd(onClose) }}>Add</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HotelModal;
