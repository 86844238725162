import React from 'react';
import CSS from './TransfersModal.module.css';
import { useState, useEffect } from 'react';
import { fetchTransferAction } from '../../../services/client/transfers';
import { setResourseValueIdAction, setPriceAction, setCostPerPaxAction } from '../../../services/client/quotations';
import { useSelector } from 'react-redux';
import { selectBookingRequest } from '../../../services/features/bookingrequest/bookingrequestSlice';

const TransfersModal = ({ onClose, transferID, index }) => {
  const [checkedIndex, setCheckedIndex] = useState(null);
  const [selectedTransport, setSelectedTransport] = useState(null);
  const transferData = useSelector((state) => state.transfers.transfer);
  const bookingRequestData = useSelector(selectBookingRequest);

  useEffect(() => {
    fetchTransferAction(transferID);
  }, [transferID]);

  const labels = [
    { type: 0, cost: transferData.carInput || 0 },
    { type: 1, cost: transferData.suvInput || 0 },
    { type: 2, cost: transferData.vanInput || 0 },
    { type: 3, cost: transferData.boatInput || 0 },
    { type: 4, cost: transferData.ferryInput || 0 }
  ];

  const transportLabels = [
    'By Car',
    'By SUV',
    'By Van',
    'By Ferry',
    'By Speedboat'
  ];

  const handleCheckboxChange = (idx) => {
    if (checkedIndex === idx) {
      setCheckedIndex(null);
      setSelectedTransport(null);
    } else {
      setCheckedIndex(idx);
      setResourseValueIdAction({ index: index, value: labels[idx].type });
      setSelectedTransport({
        transportType: labels[idx].type,
        price: labels[idx].cost
      });
    }
  };

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  const handleAddDetails = () => {
    if (selectedTransport) {
      setPriceAction({ index: index, Price: selectedTransport.price });
      setCostPerPaxAction({ index: index, cost: selectedTransport.price / bookingRequestData.no_of_pax });
      onClose();
    }
  };

  return (
    <div className={CSS.overlay}>
      <div className={CSS.popOut}>
        <h2 className={CSS.headingClass}>Transfers Details</h2>
        <table className={CSS.table}>
          <thead>
            <tr className={CSS.theadRow}>
              <th className={CSS.th}>Sr.No</th>
              <th>Transport</th>
              <th className={CSS.th}>Price</th>
              <th className={CSS.th}>Choose Transport</th>
            </tr>
          </thead>
          <tbody>
            {labels.map((label, index) => (
              <tr
                key={index}
                className={`${CSS.tbodyRowHover} ${index % 2 === 0 ? CSS.tbodyRowEven : CSS.tbodyRowOdd}`}
              >
                <td className={CSS.td}>{index + 1}</td>
                <td className={`${CSS.td} ${CSS.labelColumn}`}>{transportLabels[label.type]}</td>
                <td className={CSS.td}>{label.cost}</td>
                <td className={CSS.td}>
                  <input
                    type="checkbox"
                    className={CSS.checkbox}
                    checked={checkedIndex === index}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={CSS.buttonContainer}>
          <button className={CSS.modalButtons} onClick={onClose}>Close</button>
          <button className={CSS.modalButtons} onClick={handleAddDetails}>Add Details</button>
        </div>
      </div>
    </div>
  );
};

export default TransfersModal;
