import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CSS from "./RequestDetails.module.css";
import { useNavigate, useParams } from "react-router-dom";
import RequestIcon from "../../../../assets/icons/arrow-square-request.svg";
import request1 from "../../../../assets/icons/Request01.svg";
import {getAgentsAction,} from "../../../../services/client/agent/index";
import {selectAgents,} from "../../../../services/features/agent/agentSlice";
import {
  setAgentnameAction,
  setagentidAction,
  setcustomernameAction,
  setcustomerphoneAction,
  settraveldateAction,
  setnoofpaxAction,
  getBookingrequestAction,
  updateBookingrequestdetailsAction,
} from "../../../../services/client/bookingrequest/index";
import { selectBookingRequest } from "../../../../services/features/bookingrequest/bookingrequestSlice";

export const RequestDetails = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState("");
  const Agents = useSelector(selectAgents);
  const bookingrequestData = useSelector(selectBookingRequest);
  const navigate = useNavigate();
  const requestid = useParams();

  useEffect(() => {
    getAgentsAction();
    getBookingrequestAction(requestid.id);
  }, []);


  const handleAgentSelect = (agent) => {
    setagentidAction(agent.id);
    setSelectedAgent(agent.company);
    setShowDropdown(false);
  };

  
  const handleSubmit = () => {
    const data = {
      agent_id: parseInt(bookingrequestData.agent_id),
      customer_name: bookingrequestData.customer_name,
      customer_phone: bookingrequestData.customer_phone,
      travel_date: bookingrequestData.travel_date,
      no_of_pax: parseInt(bookingrequestData.no_of_pax),
    };

    updateBookingrequestdetailsAction(bookingrequestData.id,data, navigate);
  };

  const handleAgentname = () => {
    setAgentnameAction(selectedAgent);
  };

  const handleCustomername = (e) => {
    setcustomernameAction(e.target.value);
  };

  const handleCustomerphone = (e) => {
    setcustomerphoneAction(e.target.value);
  };

  const handleNoofpax = (e) => {
    setnoofpaxAction(e.target.value);
  };

  const handleTraveldate = (e) => {
    settraveldateAction(e.target.value);
  };

  // Function to find agent company name based on agent_id
  const findAgentCompany = (agentId) => {
    if (Agents) {
      const agent = Agents.find((agent) => agent.id === agentId);
      return agent ? agent.company : "";
    }
    return "";
  };
  // Display the company name based on selected agent_id
  const companyForAgentId = findAgentCompany(bookingrequestData.agent_id);

  const extractDate = (dateTimeString) => {
    if (!dateTimeString) return ""; // Return empty string if the input is undefined or null
    const datePart = dateTimeString.split(" ")[0]; // Extract the date part
    return datePart;
  };

  return (
    <div className={CSS.mainContainer}>
      <h2 className={CSS.H2}>GLOBAL DESTINATION COMPANY LIMITED</h2>
      <button className={CSS.RequestHead}>REQUEST</button>

      <div className={CSS.mainContent}>
        {/*background image*/}
        <div
          className={CSS.TopImg}
          style={{ backgroundImage: `url(${request1})` }}
        >
          <div className={CSS.ManiBox}>
            <div className={CSS.AllLabels}></div>
            <div className={CSS.MAinAgent}>
              <div className={CSS.AgentMain}>
                <label className={CSS.CustomAgent}>Agent Name</label>
                <input
                  className={CSS.AgentInput}
                  value={selectedAgent || companyForAgentId}
                  onClick={() => setShowDropdown(!showDropdown)}
                  onChange={handleAgentname}
                />
                {showDropdown && (
                  <div className={CSS.DropdownContent}>
                    {Agents.map((agent, index) => (
                      <div key={index} onClick={() => handleAgentSelect(agent)}>
                        {agent.company}
                      </div>
                    ))}
                  </div>
                )}
                <img
                  src={RequestIcon}
                  className={CSS.RequestIcon}
                  onClick={() => setShowDropdown(!showDropdown)}
                  alt="Request Icon"
                  onChange={handleAgentname}
                />
              </div>
              <div className={CSS.Gueatname}>
                <label className={CSS.CustomGuest}>Guest Name</label>
                <input
                  className={CSS.GuestInput}
                  type="text"
                  value={bookingrequestData.customer_name}
                  onChange={handleCustomername}
                />
              </div>
              <div>
                <label className={CSS.Agentphone}>Guest Phone</label>
                <input
                  className={CSS.GuestNumInput}
                  type="text"
                  value={bookingrequestData.customer_phone}
                  onChange={handleCustomerphone}
                />
              </div>
              <div className={CSS.CustomNopax}>
                <label className={CSS.customNopax}>No# Pax</label>
                <input
                  className={CSS.NopexInput}
                  type="text"
                  value={bookingrequestData.no_of_pax}
                  onChange={handleNoofpax}
                />
              </div>
              <div>
                <label className={CSS.CustomTravel}>Travel Date</label>
                <input
                  className={CSS.TravelInput}
                  type="date"
                  value={extractDate(bookingrequestData.travel_date)}
                  onChange={handleTraveldate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={CSS.SubmitButtonContainer}>
          <button className={CSS.buttonSubmitRequest} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestDetails;
