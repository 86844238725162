import React, { useEffect, useState } from 'react';
import CSS from './QuotationDetailCard.module.css';
import Select from 'react-select';
import DeleteIcon from '../../../assets/icons/delete.svg';
import MoreActionsIcon from '../../../assets/icons/edit.svg';
import HotelModal from '../HotelModal/HotelModal';
import SightModal from '../SightSeeingModal/SightModal';
import TransfersModal from '../TransfersModal/TransfersModal';
import { getHotelsAction } from '../../../services/client/hotel/index';
import { getSightSeeingsAction } from '../../../services/client/sightseeings/index';
import { useSelector } from 'react-redux';
import { setParticularsAction, setDateAction, deleteQuotationItemAction } from '../../../services/client/quotations';
import { selectHotels } from '../../../services/features/hotels/hotelSlice';
import { selectSightseeings } from '../../../services/features/sightseeings/SightSeeingsSlice';
import { TourDetailsData } from '../../../services/client/utils';
import { selectQuotationItems, selectQuotation } from '../../../services/features/quotationSlice/quotationSlice';
import { addQuotationItemAction, setResoueceTypeAction, setResourceIdAction, deleteAccommodationAndRoomsAction } from '../../../services/client/quotations';
import { selectTransfers } from '../../../services/features/transfers/transferSlice';
import { getTransfersAction } from '../../../services/client/transfers';
import { selectBookingRequest } from '../../../services/features/bookingrequest/bookingrequestSlice';
import { selectRequest, selectedRequestId } from '../../../services/features/quotationSlice/quotationSlice';

const QuotationDetailCards = ({ index, tourDetails, particulars, date, price, cost_per_pax, item_accomodation, rooms, pax }) => {
    const [selectedOptionInfo, setSelectedOption] = useState('');
    const [selectedParticular, setSelectedParticular] = useState(null); // New state for selected particulars
    const [showHotelModal, setShowHotelModal] = useState(false);
    const [showSightModal, setShowSightModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const hotelsData = useSelector(selectHotels);
    const sightseeingsData = useSelector(selectSightseeings);
    const transfersData = useSelector(selectTransfers);
    const [hotelID, setHotelID] = useState(null);
    const [sightId, setSightId] = useState(null);
    const [transferId, setTransferId] = useState(null);

    const bookingRequestData = useSelector(selectBookingRequest);
    const QuotationsData = useSelector(selectQuotationItems);

    let particularsData = [];

    switch (selectedOptionInfo) {
        case '0':
            particularsData = hotelsData.map(item => ({ value: item.id, label: item.name }));
            break;
        case '2':
            particularsData = sightseeingsData.map(item => ({ value: item.id, label: item.name }));
            break;
        case '3':
            particularsData = transfersData.transfers.map(item => ({ value: item.id, label: item.from + ' to ' + item.to }));
            break;
        default:
            particularsData = [];
            break;
    }

    //handle tours change
    const handleToursChange = (selectedOption) => {
        setSelectedOption(selectedOption.value);
        setSelectedParticular(null); // Reset the selected particulars
        setResoueceTypeAction({ index: index, value: parseInt(selectedOption.value) });

        if (selectedOption.value === '0') {
            getHotelsAction();
        } else if (selectedOption.value === '2') {
            getSightSeeingsAction();
        } else if (selectedOption.value === '3') {
            getTransfersAction();
        }
    }

    //handle particulars change.
    const handleParticularsChange = (selectedOption) => {
        setSelectedParticular(selectedOption); // Set the selected particulars
        setResourceIdAction({ index: index, value: selectedOption.value });

        const resource = {
            index: index,
            data: selectedOption
        }

        setParticularsAction(resource);

        // Update ID based on selected option
        if (selectedOptionInfo === '0') {
            setHotelID(selectedOption.value);
            setShowHotelModal(true);
        } else if (selectedOptionInfo === '2') {
            setSightId(selectedOption.value);
            setShowSightModal(true);
        } else if (selectedOptionInfo === '3') {
            setTransferId(selectedOption.value);
            setShowTransferModal(true);
        }
    }

    //modals handling.
    const handleModalsChange = () => {
        // Open modal based on selected option
        if (selectedOptionInfo === '0') {
            setShowHotelModal(true);
        } else if (selectedOptionInfo === '2') {
            setShowSightModal(true);
        } else if (selectedOptionInfo === '3') {
            setShowTransferModal(true);
        }
    }

    const handleDeleteQuotationItem = (index) => {
        deleteQuotationItemAction(index);
    }

    const handleDate = (e) => {
        const date = {
            index: index,
            date: e.target.value
        }
        setDateAction(date);
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: 30,
            width: 180,
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: 5,
            width: 180,
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: state.isSelected ? 0 : 0,
        }),
    };

    const particularStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: 30,
            width: 280,
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: 5,
            width: 280,
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: state.isSelected ? 0 : 0,
        }),
    };

    //formatting the date
    const setDateFormat = (date) => {
        const newDate = new Date(date);
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
        const day = String(newDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    //setting the tour details value.
    const tourDetailsValue = {
        value: `${tourDetails}`,
        label: tourDetails === 0 ? `Hotel` : tourDetails === 2 ? `Sightseeing` : tourDetails === 3 ? `Transfers` : ``
    }

    //particulars value.
    const particularsValue = {
        value: `${selectedParticular?.value || ''}`,
        label: (tourDetails === 3)
            ? `${(selectedParticular?.from && selectedParticular?.to) ? `${selectedParticular.from} to ${selectedParticular.to}` : 'Particulars'}`
            : (tourDetails === 2 || tourDetails === 0)
                ? 'Particulars'
                : `${selectedParticular?.label || ''}`
    };

    useEffect(() => {
        if (tourDetailsValue) {
            // handleToursChange(tourDetailsValue)
        }
    })

    return (
        <table className={CSS.mainQuotationCardContainer}>
            <tbody>
                <tr>
                    <td className={CSS.SerialNumber}>
                        {index + 1}
                    </td>
                    <td className={CSS.tourDetailsContainer}>
                        <Select
                            options={TourDetailsData}
                            onChange={handleToursChange}
                            placeholder={tourDetailsValue ? tourDetailsValue : "Select tours"}
                            styles={customStyles}
                            value={tourDetailsValue}
                        />
                    </td>
                    <td className={CSS.particularsContainer}>
                        <Select
                            options={particularsData}
                            value={selectedParticular}
                            onChange={handleParticularsChange}
                            placeholder={"Particulars"}
                            styles={particularStyles}
                        />
                    </td>
                    <td className={CSS.DateContainer}>
                        <input
                            type="date"
                            className={CSS.dateInput}
                            onChange={handleDate}
                            value={setDateFormat(date)}
                        />
                    </td>
                    <td className={CSS.PriceContainer}>
                        <input
                            type='text'
                            placeholder="Price"
                            value={QuotationsData[index].total_price}
                            className={CSS.priceInput}
                            readOnly
                        />

                    </td>
                    <td className={CSS.PriceContainer}>
                        <input
                            type='text'
                            placeholder="Cost Per Pax"
                            className={CSS.priceInput}
                            value={cost_per_pax}
                            readOnly
                        />

                    </td>
                    <td className={CSS.actionsContainer}>
                        <img src={MoreActionsIcon} alt="more actions" className={CSS.iconClass} title='More actions' onClick={() => handleModalsChange} />
                        <img src={DeleteIcon} alt="delete" className={CSS.iconClass} title="Delete item" onClick={() => handleDeleteQuotationItem(index)} />
                    </td>
                </tr>
            </tbody>

            {/* hotel modal */}
            {showHotelModal && <HotelModal onClose={() => setShowHotelModal(false)} hotelID={hotelID} index={index} />}

            {/* sight modal */}
            {showSightModal && <SightModal onClose={() => setShowSightModal(false)} sightID={sightId} index={index} />}

            {/* transfer modal */}
            {showTransferModal && <TransfersModal onClose={() => setShowTransferModal(false)} transferID={transferId} index={index} />}
        </table>
    );

};

const QuotationDetailCard = () => {
    const quotationItems = useSelector(selectQuotationItems); //whole quotation items.
    const request = useSelector(selectRequest);

    const rooms = quotationItems.map(item => item.item_accomodation ? item.item_accomodation.rooms : []);

    const handleAddMore = () => {
        addQuotationItemAction();
    };

    return (
        <div className={CSS.newContainerMain}>
            {quotationItems.map((item, index) => (
                <QuotationDetailCards
                    key={index}
                    index={index}
                    tourDetails={item.resource_type}
                    particulars={item.resource}
                    date={item.date}
                    cost_per_pax={item.cost_per_pax}
                    item_accomodation={item.item_accomodation}
                    rooms={rooms}
                    pax={request.no_of_pax}
                />
            ))}
            <div className={CSS.addMoreButtonContainer}>
                <button className={CSS.addMoreButton} onClick={handleAddMore}>Add</button>
            </div>
        </div>
    );
};

export default QuotationDetailCard;
