import React, { useEffect } from "react";
import CSS from "./TransferDetails.module.css";
import { useSelector } from "react-redux";
import { setTransferValuesAction, updateTransferAction } from "../../../services/client/transfers";
import From from "../../shared/Fromto/From";
import To from "../../shared/Fromto/To";
import SelectFrom from "../../shared/Fromto/SelectFrom";
import SelectTo from "../../shared/Fromto/SelectTo";
import { TravelType } from "../../../services/client/utils";
import { FromPlace } from "../../../services/client/utils";
import { useNavigate, useParams } from "react-router-dom";
import { fetchTransferAction } from "../../../services/client/transfers/index";
import ProgressLoader from "../../shared/ProgressLoader/ProgressLoader";

const TransferDetails = () => {
  const transferData = useSelector((state) => state.transfers.transfer);
  const loading = useSelector((state) => state.transfers.loading);
  const error = useSelector((state) => state.transfers.error);
  const navigate = useNavigate();
  const transferid = useParams();


  useEffect(() => {
    fetchTransferAction(transferid.id);
  }, []);

  const handleSubmit = () => {
    const data = {
      id: transferData.id,
      from_type: transferData.selectedFromType,
      to_type: transferData.selectedToType,
      from: transferData.fromValue,
      to: transferData.toValue,
      types: [
        {
          type: 0,
          cost: transferData.carInput || 0,
        },
        {
          type: 1,
          cost: transferData.suvInput || 0,
        },
        {
          type: 2,
          cost: transferData.vanInput || 0,
        },
        {
          type: 3,
          cost: transferData.ferryInput || 0,
        },
        {
          type: 4,
          cost: transferData.boatInput || 0,
        },
      ],
    };

    updateTransferAction(data, navigate);
  };

  const handleFromTypeSelect = (value) => {
    const selectedValue = FromPlace.find((item) => item.value === value);
    setTransferValuesAction({
      selectedFromType: selectedValue.type
    });
  };

  const handleToTypeSelect = (value) => {
    const selectedValue = FromPlace.find((item) => item.value === value);
    setTransferValuesAction({
      selectedToType: selectedValue.type
    })
  };

  const handleFromValueChange = (item) => {
    setTransferValuesAction({
      fromValue: item.name,
    });
  };

  const handleToValueChange = (item) => {
    setTransferValuesAction({
      toValue: item.name,
    })
  };

  const handleCarPrice = (value) => {
    setTransferValuesAction({carInput:value});
  };

  const handleSUVPrice = (value) => {
    setTransferValuesAction({suvInput:value});
  };
  const handleVanPrice = (value) => {
    setTransferValuesAction({vanInput:value});
  };
  const handleFerryPrice = (value) => {
    setTransferValuesAction({ferryInput:value});
  };
  const handleBoatPrice = (value) => {
    setTransferValuesAction({boatInput:value});
  };
  const handleTypes = (e, type) => {
    const value = parseInt(e.target.value)
    switch (type.name) {
      case "By Car":
        handleCarPrice(value);
        break;
      case "By Suv":
        handleSUVPrice(value);
        break;
      case "By Van":
        handleVanPrice(value);
        break;
      case "By Ferry":
        handleFerryPrice(value);
        break;
      case "By Boat":
        handleBoatPrice(value);
        break;
      default:
        break;
    }
  }
  const getTypesValue = (type) => {
   return type.name === "By Car"
                      ? transferData.carInput
                      : type.name === "By Suv"
                      ? transferData.suvInput
                      : type.name === "By Van"
                      ? transferData.vanInput
                      : type.name === "By Ferry"
                      ? transferData.ferryInput
                      : type.name === "By Boat"
                      ? transferData.boatInput
                      : ""
  }

  if (loading || !transferData.id) return <ProgressLoader />
  
  return (
    <div>
      <div>
        <div>
          <div className={CSS.From}>
            <div>
              <h6 className={CSS.FromHeader}>From</h6>
              <div className={CSS.FromPlaceContainer}>
                <div>
                  <From
                    onSelectChange={handleFromTypeSelect}
                    type={FromPlace.find((item) => item.type === transferData.selectedFromType).value}
                  />
                </div>
              </div>
            </div>
            <div>
              <SelectFrom
                selectedType={FromPlace.find((item) => item.type === transferData.selectedFromType).value}
                onFromValueChange={handleFromValueChange}
                currentValue={transferData.fromValue}
              />
            </div>
          </div>
          <div className={CSS.To}>
            <div className={CSS.FromTitleContainer}>
              <h6 className={CSS.ToHeader}>To</h6>
              <div className={CSS.FromPlaceContainer}>
                <div>
                  <To
                    onSelectChange={handleToTypeSelect}
                    type={FromPlace.find((item) => item.type === transferData.selectedToType).value}
                  />
                </div>
              </div>
            </div>
            <div>
              <SelectTo
                selectedType={FromPlace.find((item) => item.type === transferData.selectedToType).value}
                onToValueChange={handleToValueChange}
                currentValue={transferData.toValue}
              />
            </div>
          </div>
          <div className={CSS.FromBoxTo}>
            <div className={CSS.FromBox}>{transferData.fromValue}</div>
            <h6 className={CSS.ToTitle}>To</h6>
            <div className={CSS.ToBox}>{transferData.toValue}</div>
          </div>
          <div className={CSS.TransType}>
            <div className="d-flex flex-column gap-2">
            {
            TravelType.filter((item) => ([0,1,2].includes(item.type))).map((type) => (
              <div className='d-flex align-items-center gap-1'
                key={type.type}>
                <label>
                  {type.name}
                </label>
                <input
                  type="text"
                  id={`${type.name.toLowerCase()}Input`}
                  name="Input"
                  value={getTypesValue(type)}
                  onChange={(e) => { handleTypes(e, type)}}
                  className={
                    type.name === "By Ferry" || type.name === "By Boat"
                      ? CSS.FerryBoatInput
                      : CSS.TransInput
                  }
                />
                <br />
              </div>
            ))}
            </div>
            <div className="d-flex flex-column gap-2">
            {
            TravelType.filter((item) => ([3,4].includes(item.type))).map((type) => (
              <div className='d-flex align-items-center gap-1'
                key={type.type}
              >
                <label>
                  {type.name}
                </label>
                <input
                  type="text"
                  id={`${type.name.toLowerCase()}Input`}
                  name="Input"
                  value={getTypesValue(type)}
                  onChange={(e) => { handleTypes(e, type)}}
                  className={
                    type.name === "By Ferry" || type.name === "By Boat"
                      ? CSS.FerryBoatInput
                      : CSS.TransInput
                  }
                />
                <br />
              </div>
            ))}
            </div>
          </div>
        </div>
      </div>

      {error && <p>Error: {error}</p>}
      <div className={`${CSS.actions} d-flex gap-4 my-4 justify-content-end`}>

      <button
          className={CSS.backButtonText}
          onClick={() => navigate(-1)}
          disabled={loading}
        >
          {"Back"}
        </button>
        <button
          className={CSS.saveButtonText}
          onClick={handleSubmit}
          disabled={loading}
        >
          {"Save"}
        </button>
      </div>
    </div>
  );
};

export default TransferDetails;
