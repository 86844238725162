import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getQuotationsApi, getQuotation, deleteQuotationApi, updateQuotationApi } from "../../api/quotations";

//fetch quotations API.
export const fetchQuotations = createAsyncThunk(
    "quotations/fetchQuotations",
    async (params) => {
        const response = await getQuotationsApi(params);
        return response.data;
    }
);

//fetch Quotation API.
export const fetchQuotation = createAsyncThunk(
    "quotations/fetchQuotation",
    async (id) => {
        const response = await getQuotation(id);
        return response.data;
    }
);

//delete Quotation API.
export const deleteQuotation = createAsyncThunk(
    "quotations/deleteQuotation",
    async (id) => {
        const response = await deleteQuotationApi(id);
        return response.data;
    }
);

//update Quotation API.
export const updateQuotation = createAsyncThunk(
    "quotations/updateQuotation",
    async (payload) => {
        const response = await updateQuotationApi(payload.id, payload.data);
        return response.data;
    }
);


const quotationSlice = createSlice({
    name: "quotations",
    initialState: {
        quotation_id: null,
        request_id: null,
        quotationItems: [],
        request: {},
        quotations: [],
        status: 'idle', 
        error: null,
    },
    reducers: {
        addQuotationItem: (state, action) => {
            state.quotationItems.push(action.payload);
        },
        setResoueceType: (state, action) => {
            state.quotationItems[action.payload.index].resource_type = action.payload.value;
        },
        setResourceId: (state, action) => {
            state.quotationItems[action.payload.index].resource_id = action.payload.value;
        },
        setResourseValueId: (state, action) => {
            state.quotationItems[action.payload.index].resource_id_value = action.payload.value;
        },
        setParticulars: (state, action) => {
            const { index, data } = action.payload;
            state.quotationItems[index].resource = data;
        },
        setHotelRooms: (state, action) => {
            const { index, rooms } = action.payload;
            state.quotationItems[index].rooms = rooms;
        },
        setDate: (state, action) => {
            const { index } = action.payload;
            state.quotationItems[index].date = action.payload.date;
        },
        setPrice: (state, action) => {
            state.quotationItems[action.payload.index].total_price = action.payload.Price;
        },
        setCostPerPax: (state, action) => {
            state.quotationItems[action.payload.index].cost_per_pax = action.payload.cost;
        },
        setRequestID: (state, action) => {
            const { index } = action.payload;
            state.quotationItems[index].request_id = action.payload;
        },
        setCheckIn: (state, action) => {
            const { index } = action.payload;
            state.quotationItems[index].item_accommodation.check_in = action.payload.date;
        },
        setCheckOut: (state, action) => {
            const { index } = action.payload;
            state.quotationItems[index].item_accommodation.check_out = action.payload.date;
        },
        setNumberOfNights: (state, action) => {
            const { index } = action.payload;
            state.quotationItems[index].item_accommodation.no_of_nights = action.payload.nights;
        },

        clearQuotationItems: (state) => {
            state.quotationItems = [];
            state.quotation = {
                id: null,
                request_id: null,
                quotation_items: []
            };
        },

        deleteQuotationItem: (state, action) => {
            state.quotationItems = state.quotationItems.filter((item, index) => index !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchQuotations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchQuotations.fulfilled, (state, action) => {
                state.status = 'succeeded';

                state.quotations = action.payload;
                state.request_id = action.payload.request_id;
                // state.quotation_id = action.payload.id;
            })
            .addCase(fetchQuotations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(fetchQuotation.pending, (state) => {
                state.status = 'loading';
            })
  
            .addCase(fetchQuotation.fulfilled, (state, action) => {
                state.status = 'succeeded';

                //checking the quotation items.
                if(state.quotationItems.length === 0){
                    state.quotationItems = action.payload.items;
                }
                else{
                    
                    //empty the quotation items
                    state.quotationItems = [];
                    state.quotationItems = action.payload.items; 
                    
                }
                
                state.request_id = action.payload.request_id;
                state.quotation_id = action.payload.id;
                state.request = action.payload.request;   
                
            })

            .addCase(fetchQuotation.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            //delete quotation
            .addCase(deleteQuotation.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteQuotation.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.quotationItems = state.quotationItems.filter((item) => item.id !== action.payload.id);
                state.request_id = null;
                state.quotation_id = null;
            })

            .addCase(deleteQuotation.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(updateQuotation.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.quotationItems = state.quotationItems.filter((item) => item.id !== action.payload.id);
                state.request_id = null;
                state.quotation_id = null;
            })



    },
});

export const selectQuotation = (state) => state.quotations.quotation;
export const selectedRequestId = (state) => state.quotations.request_id;
export const selectRequest = (state) => state.quotations.request;
export const selectRooms = (state) => state.quotations.rooms;
export const selectQuotationItems = (state) => state.quotations.quotationItems;
export const selectQuotations = (state) => state.quotations.quotations;

export default quotationSlice.reducer;
export const { 
    addQuotationItem, 
    setParticulars, 
    setDate, 
    setPrice, 
    setHotelRooms, 
    setRequestID, 
    setRooms, 
    setCheckIn, 
    setCheckOut, 
    setNumberOfNights, 
    deleteQuotationItem,  
    setResoueceType, 
    setResourceId, 
    setResourseValueId, 
    clearQuotationItems,
    setCostPerPax
} = quotationSlice.actions;
